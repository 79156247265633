import { buildAuthorizationHeader } from '@/api/http';
import axios from 'axios';
import { getClientInfo } from '@/utils/clientUtils';
import { SessionLength } from '@/constants/browserStorage';
import type { SessionDTO } from '@/data/Session';
import { LOGIN } from '@/constants/endpoint';
import { type AuthenticatedSession } from '@/stores/useSessionStore';
import { getDomain } from '@/composables/useStorageSession';
import { buildFormData, builtDomainUrl } from '@/utils/httpUtils';

export interface UserCredentials {
  email: string;
  password: string;
}

type ApiSession = SessionDTO & { domain: string; sessionLength?: SessionLength };

interface LoginData {
  username: string;
  password: string;
  domain: string;
  client_info: string;
  duration: SessionLength;
}

const mapSessionDTOToSession = (sessionDto: ApiSession): AuthenticatedSession => ({
  token: sessionDto.token,
  expiryDate: sessionDto.expiry_timestamp,
  domain: sessionDto.domain,
  userUuid: sessionDto.user_uuid,
  sessionLength: sessionDto.sessionLength ?? SessionLength.SHORT,
  isAuthenticated: true,
});

const createAuthenticationParams = (props: UserCredentials, sessionLength: SessionLength): LoginData => {
  const { email, password } = props;
  const [username, domain] = email.toLowerCase().split('@');

  return {
    username,
    password,
    domain,
    client_info: getClientInfo(),
    duration: sessionLength,
  };
};

export const authClientApi = async (userCredentials: UserCredentials, sessionLength: SessionLength): Promise<AuthenticatedSession> => {
  const authenticationParams = createAuthenticationParams(userCredentials, sessionLength);
  const baseURL = builtDomainUrl(authenticationParams.domain);
  const URL = `${baseURL}${LOGIN}`;
  const data = buildFormData(authenticationParams);

  return axios
    .post<SessionDTO>(URL, data)
    .then((r) => ({ ...r.data, domain: authenticationParams.domain, sessionLength: authenticationParams.duration }))
    .then(mapSessionDTOToSession);
};

export const logoutUser = async () => {
  const baseURL = builtDomainUrl(getDomain()!);
  const URL = `${baseURL}/login.php`;
  await axios.post<SessionDTO>(URL, null, { params: { action: 'logout' }, headers: buildAuthorizationHeader() });
};

export const renewLogin = async (domain: string, sessionLength: SessionLength): Promise<AuthenticatedSession> => {
  const clientData: Record<string, string> = {
    client_info: getClientInfo(),
    duration: sessionLength,
  };

  return axios
    .post<SessionDTO>(LOGIN, buildFormData(clientData), {
      baseURL: builtDomainUrl(domain),
      params: { action: 'renew' },
      headers: buildAuthorizationHeader(),
    })
    .then((r) => ({ ...r.data, domain, sessionLength }))
    .then(mapSessionDTOToSession);
};
