import { useSessionStore } from '@/stores/useSessionStore';
import { authClientApi, logoutUser, renewLogin, type UserCredentials } from '@/api/authClientApi';
import { createDomainAxiosInstance } from '@/api/axios';
import type { SessionLength } from '@/constants/browserStorage';

export const useAuthService = () => {
  const $sessionStore = useSessionStore();

  const login = async (userCredentials: UserCredentials, sessionLength: SessionLength) => {
    const session = await authClientApi(userCredentials, sessionLength);
    createDomainAxiosInstance(session.domain);
    $sessionStore.createSession({ ...session, sessionLength: sessionLength });
  };

  const logout = async () => {
    await logoutUser();
    await $sessionStore.logoutSession();
  };

  const refreshToken = async () => {
    if ($sessionStore._session.isAuthenticated === false) return Promise.reject('Not authenticated');
    const { domain, sessionLength } = $sessionStore._session;
    try {
      const newSessionData = await renewLogin(domain, sessionLength);
      createDomainAxiosInstance(newSessionData.domain);
      $sessionStore.createSession(newSessionData);
    } catch (error) {
      console.error('Error refreshing token:', error);
      throw error;
    }
  };

  const handleSSOLogin = async () => {
    const ssoServerURL = getSSOServerURL();
    const redirectURL = window.location.host;
    window.location.href = `${ssoServerURL}/wtgapi/v2/oauth2/authorization/microsoft?state=${redirectURL}`;
  };

  const getSSOServerURL = () => {
    // case for development purposes
    if (import.meta.env.VITE_SSO_LOCAL_BASE_URL) return import.meta.env.VITE_SSO_LOCAL_BASE_URL;

    switch (DEPLOY_ENV) {
      case 'prod':
      case 'stage-porta':
      case 'stage-prod':
        return 'https://sales.wtg.cloud';
      case 'dev':
        return 'https://basic.wtg.cloud';
      case 'qa':
        return 'https://intern.wtg.cloud';
      default:
        return 'https://sales.wtg.cloud';
    }
  };

  return {
    login,
    logout,
    refreshToken,
    handleSSOLogin,
  };
};
