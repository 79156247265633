import { EmptySession, isAuthenticatedSession, type Session, type UnknownSession } from '@/stores/useSessionStore';
import { getDataFromBrowserStorage } from '@/utils/storage';
import { BrowserStorageKey, SessionLength } from '@/constants/browserStorage';

export const getDomain = () => getDataFromBrowserStorage(BrowserStorageKey.DOMAIN) ?? undefined;
export const getExpirationToken = () => getDataFromBrowserStorage(BrowserStorageKey.LOGIN_TOKEN_EXPIRY) ?? undefined;
const getLoggedInUserUuid = () => getDataFromBrowserStorage(BrowserStorageKey.LOGGED_IN_USER_UUID) ?? undefined;
const getLoginToken = () => getDataFromBrowserStorage(BrowserStorageKey.LOGIN_TOKEN) ?? undefined;
// if user has decided to stay logged in we make the session longer and will keep the data in local storage
const getSessionLength = () => (getDataFromBrowserStorage(BrowserStorageKey.SESSION_LENGTH) as SessionLength) ?? undefined;

const getSessionDataFromStorage = (): UnknownSession => {
  return {
    sessionLength: getSessionLength(),
    domain: getDomain(),
    token: getLoginToken(),
    expiryDate: getExpirationToken(),
    userUuid: getLoggedInUserUuid(),
  };
};

export const useStorageSession = (): Session => {
  const session = getSessionDataFromStorage();
  return isAuthenticatedSession(session) ? { ...session, isAuthenticated: true } : EmptySession;
};
