import 'vuetify/styles';
import { createVuetify, type ThemeDefinition } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import { VFileUpload } from 'vuetify/labs/VFileUpload';

const wtgTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#db0d15',
    error: '#db0d15',
  },
};

export const vuetify = createVuetify({
  components: {
    VFileUpload,
    ...components,
  },
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  defaults: {
    VSelect: {
      variant: 'outlined',
      density: 'compact',
      hideDetails: true,
      menuProps: {
        offset: 8,
      },
    },
  },
  theme: {
    defaultTheme: 'wtgTheme',
    themes: {
      wtgTheme,
    },
  },
});
