{
    "contact-details": {
        "email": "E-mail",
        "title": "Kontaktní údaje",
        "location": "Místo",
        "address": "Adresa",
        "postcode": "PSČ",
        "position": "Pozice",
        "edit": "Upravit kontaktní údaje",
        "add": "Přidání nového kontaktu",
        "country": "Země",
        "contact-delete": "Chcete tento kontakt odstranit?",
        "contact-delete-title": "Odstranění kontaktu"
    },
    "integration-settings": {
        "title": "Nastavení integrace Azure",
        "description": "Zadáním údajů automaticky aktivujete integraci Azure, která zahrnuje synchronizaci přítomnosti v MS Teams a synchronizaci kontaktů.",
        "label": "Integrace",
        "AzureSettings": "Integrace Azure",
        "azure-client-id": "ID aplikace (klienta)",
        "azure-client-secret": "Hodnota tajného klíče klienta",
        "azure-tenant-id": "ID adresáře (nájemce)",
        "delete-config-msg": "Opravdu chcete smazat konfiguraci Azure?",
        "configuration": "Konfigurace Azure"
    },
    "invalid-uuid": "Hodnota by měla být ve formátu UUID",
    "invalid-data": "Poskytnuty neplatné údaje. Prosím zkontrolujte a zkuste znovu",
    "add": "Přidat",
    "yes-delete": "Ano, vymazat",
    "add-user": "Přidat uživatele",
    "closewindowdespitecall": "Stále probíhá hovor, přesto chcete zavřít?",
    "admin-portal": "Portál Admin",
    "internal": "Interní",
    "external": "Externí",
    "inbound": "Příchozí",
    "search": "Hledat",
    "unsavedchanges": {
        "validmessage": "Neuložené změny",
        "invalidmessage": "Neuložené změny nelze uložit",
        "valid-content": "Některé změny nejsou zachovány. Chcete je před odchodem uložit?",
        "invalid-content": "Existují některé neuložené neplatné změny. Chcete je před odchodem vrátit?",
        "content": "Existují neuložené změny. Přejete si je zrušit před odchodem?",
        "revert": "Zahodit změny",
        "cancel": "Přizpůsobit změny",
        "save": "Uložit"
    },
    "changelicense": {
        "title": "Změna licence",
        "content": "Upozorňujeme, že změna typu licence může mít obchodní důsledky a vést ke změně nákladů. Opravdu chcete změnit uživatelskou licenci na licenci \"{license}\"?",
        "cancel": "Zrušení",
        "save": "Ano, změna"
    },
    "loading": "Načítání …",
    "agentspace": "Panel agentů",
    "set-agent-status": "Nastavení stavu agenta",
    "choose-agent-status": "Zvolte status agenta.",
    "set-status": "Nastavit status",
    "agents": "Agenti",
    "agent": "Agenti",
    "queue": "Fronta",
    "agent-status": {
        "all": "Vše",
        "in-call": "V Rozhovoru",
        "logged-out": "Odhlášeno",
        "logged-in": "Přihlášen",
        "available": "Dostupný",
        "break": "Pauza",
        "wrap-up": "Následné Zpracování",
        "inactive": "Neaktivní"
    },
    "homeview": {
        "no-missed-calls": "Nejsou žádné nové zmeškané hovory.",
        "up-to-date": "Jste aktuální."
    },
    "unknown": "Neznámý",
    "post-processing-time": "Doba dodatečného zpracování",
    "call-reason": "Důvod volání",
    "call-history": "Historie volajících",
    "call_history": "Historie volání",
    "queues-waiting": "Čeká",
    "request_supervisor": "Vyžádat si supervizora",
    "requests_supervisor": "Dotaz na supervizora",
    "supervisor": "Supervizor",
    "referred-by": "Odesláno",
    "diverted-by": "Předáno",
    "name": "Jméno",
    "status": "Stav",
    "all": "Zobrazit vše",
    "number": "Číslo",
    "position": "Pozice",
    "waiting-time": "Doba čekání",
    "steady": "Doba trvání",
    "close": "Zavřít",
    "calls": "Volání",
    "replayed": "Zodpovězeno",
    "canceled": "Přerušeno",
    "service-level": "Úroveň služby",
    "call-detail": "Podrobnosti o volání",
    "phone": "Telefon",
    "offline_message": "Jste odhlášeni",
    "break_message": "Máte přestávku",
    "no_agent": "Nemáte žádného přiděleného agenta",
    "forward_to": "Předat",
    "connect_to": "Sdělit",
    "cancel": "Přerušit",
    "more": "Více",
    "merge": "Uzavřít zprostředkování",
    "warm": "Teplé",
    "cold": "Studený",
    "hang-up": "Zavěsit",
    "parking": "Parkování",
    "warmtransfer": "Sdělit",
    "coldtransfer": "Předat",
    "hold": "Držet",
    "speaker": "Reproduktor",
    "unhold": "Získat zpět",
    "mute": "Ztlumit",
    "addparticipant": "Přidat účastníka",
    "soundoutputsettings": "Nastavení výstupu",
    "participant": "Účastník",
    "keypad": "Číselné pole",
    "dialpad": "Vytáčení",
    "endcall": "Odejít",
    "insertnameornumber": "Zadejte jméno nebo číslo",
    "group": "Skupina",
    "declined": "Odmítnuto",
    "timeout": "Překročen časový limit",
    "busy": "Obsazeno",
    "add-contact-number": "Přidat kontakt, číslo",
    "conference-member": "Účastník konference",
    "back": "Vrátit se",
    "failed": "Nedosažitelný",
    "hung-up": "Zavěsil",
    "sound-error": "Problémy s mikrofonem",
    "save": "Uložit",
    "extension": "Telefonní pobočka",
    "phonelabel": {
        "mobile": "Mobilní",
        "fax": "Fax",
        "work": "Práce",
        "other": "Jiné"
    },
    "addresslabel": {
        "work": "Práce"
    },
    "video": "Video",
    "work": "Práce",
    "favorites": "Oblíbené",
    "allContacts": "Všechny kontakty",
    "favoriteAdd": "Přidat k oblíbeným",
    "favoriteRemove": "Odebrat z oblíbených",
    "importedFrom": "Importováno z",
    "AssistantTelephoneNumber": "Asistent",
    "BusinessTelephoneNumber": "Podnikání",
    "Business2TelephoneNumber": "Podnikání 2",
    "BusinessFaxNumber": "Faxový obchod",
    "CallbackTelephoneNumber": "Zpětná zpráva",
    "CarTelephoneNumber": "Auto",
    "company": "Společnost",
    "HomeTelephoneNumber": "Soukromé",
    "Home2TelephoneNumber": "Soukromé 2",
    "HomeFaxNumber": "Soukromý fax",
    "ISDNNumber": "ISDN",
    "MobileTelephoneNumber": "Mobilní telefon",
    "OtherFaxNumber": "Další fax",
    "OtherTelephoneNumber": "Další",
    "PrimaryTelephoneNumber": "Hlavní telefon",
    "RadioTelephoneNumber": "Rádiové volání",
    "TelexNumber": "Telex",
    "TTYTDDTelephoneNumber": "Textový telefon",
    "home": "Domov",
    "statistics": "Statistiky",
    "hourabbrev": "hod",
    "minuteabbrev": "min",
    "secondabbrev": "s",
    "totallogintime": "Celková doba přihlášení",
    "averagecallduration": "Průměrná doba hovoru",
    "acceptedcalls": "Přijaté hovory",
    "missedcalls": "Zmeškaná volání",
    "nomissedcalls": "O nic jste nepřišli.",
    "languages": "Jazyky",
    "language": "Jazyk",
    "logIn": "Přihlásit",
    "password": "Heslo",
    "stayLoggedIn": "Zůstat přihlášeni",
    "forgotPassword": "Zapomenuté heslo?",
    "userLogin": "Uživatel",
    "autologinfailed": "Automatické přihlášení se nezdařilo",
    "unknownversion": "Neznámá verze",
    "userneedsdomain": "Selhalo: Uživatelské jméno musí obsahovat doménu.",
    "userhasnoextension": "Selhalo: Uživatel nemá přiřazenou žádnou telefonní pobočku.",
    "wrongcredentials": "Selhalo: Uživatel a heslo se neshodují.",
    "inactiveUser": "Chyba: Uživatel není aktivní.",
    "serverunreachable": "Selhalo: Server {0} není dostupný.",
    "checkwtgcloud": "Myslíte wtg.cloud místo wtg.com?",
    "unknownerror": "Neznámý chybový stav: {0}",
    "mobiletitel": "Telefon",
    "history": "Průběh",
    "numpad": "Číselník",
    "recordings": "Hlasové zprávy",
    "voicemails": "Nahrávky",
    "historytabs": {
        "all": "Vše",
        "missed": "Zmeškané",
        "inbound": "Příchozí",
        "outbound": "Odchozí"
    },
    "enternameornumber": "Zadejte číslo",
    "internalextensionnumber": "Provolba",
    "externalextensionnumber": "Moje číslo",
    "request-password-reset-header": "Zapomenuté heslo?",
    "user-at-dot": "Uživatelské jméno musí obsahovat znak {'@'} a tečku „.“.",
    "reset-password": "Obnovit heslo",
    "return-to-login": "Zpět k přihlášení",
    "passwordreset-success-msg": "Na uloženou adresu byl zaslán e-mail k obnovení hesla. Zkontrolujte svou poštovní schránku.",
    "not-working": "Bohužel to nefungovalo.",
    "error403": "Možná jste požádali o příliš mnoho obnovení hesla najednou.",
    "error400": "Zkontrolujte své uživatelské jméno a zkuste to znovu.",
    "error-domain": "Zkontrolujte doménu (část za znakem „{'@'}“).",
    "resetsuccesstitle": "Heslo bylo obnoveno",
    "resetsuccessmessage": "Heslo bylo obnoveno.",
    "tologin": "K přihlášení",
    "resetpassword": "Obnovit heslo",
    "newpassword": "Nové heslo",
    "passwordhastocontain8chars": "Heslo musí obsahovat alespoň 8 znaků.",
    "repeatpassword": "Zopakovat heslo",
    "passwordsdontmatch": "Hesla se neshodují.",
    "savepassword": "Uložit heslo",
    "settings": "Nastavení",
    "user": {
        "deletePhotoConfirmationTitle": "Profilový obrázek",
        "deletePhotoConfirmationMessage": "Opravdu chcete obrázek smazat?",
        "userprofile": "Profil",
        "profilepicture": "Profilový obrázek",
        "newprofilepicture": "Nový profilový obrázek",
        "deleteprofilepicture": "Smazat profilový obrázek",
        "uploadprofilepicture": "Nahrát",
        "changepassword": "Změnit heslo",
        "oldpassword": "Staré heslo",
        "newpassword": "Nové heslo",
        "repeatnewpassword": "Zopakovat heslo",
        "extension": "Telefonní pobočka",
        "extensionpassword": "Heslo k telefonním pobočkám",
        "language": "Jazyk",
        "passwordwaschanged": "Heslo změněno.",
        "useatleast8characters": "Heslo musí obsahovat alespoň 8 znaků.",
        "passwordsdontmatch": "Hesla se neshodují",
        "passwordchangeerror": "To se nepodařilo. Zkontrolujte prosím své staré heslo a zkuste to znovu.",
        "languages": {
            "de": "Německy",
            "en": "Anglicky",
            "fr": "Francouzsky",
            "cs": "Česky"
        },
        "disablePhone": "Deaktivovat telefonii v softclientu"
    },
    "callforward": {
        "callforward": "Přesměrování volání",
        "target": "Cíl",
        "all": "Přesměrování všech volání",
        "alldescription": "Přesměrování všech příchozích hovorů na cíl.",
        "busy": "Když je obsazeno",
        "busydescription": "Pokud je aktivní, bude aktivovaná poštovní schránka telefonní pobočky přepsána.",
        "noanswer": "Žádná odpověď",
        "noanswerdescription": "Pokud je aktivní, bude aktivovaná poštovní schránka telefonní pobočky přepsána.",
        "notregistered": "Neregistrováno",
        "notregistereddescription": "Přesměrování volání na nové místo určení, pokud je původní místo určení před aktivací schránky nedosažitelné."
    },
    "followme": {
        "deleteTargetConfirmationTitle": "Následuj mě",
        "deleteTargetConfirmationMessage": "Opravdu chcete smazat cíl?",
        "followme": "Přesměrování hovorů",
        "target": "Cíl",
        "delay": "Zpoždění",
        "timeout": "Čas odložení",
        "prompt": "Dotazy",
        "delete": "Smazat",
        "prompton": "Zap",
        "promptoff": "Vyp",
        "adddestination": "Přidat",
        "ignorebusy": "Ignorovat obsazeno",
        "ignorebusydescription": "Přerušit volání, když je místo určení obsazené."
    },
    "extensionsettings": {
        "extensionsettings": "Nastavení linky",
        "busyonbusydecision": "Více příchozích volání",
        "busyonbusy": "Busy-On-Busy",
        "allowcallwaiting": "Povolit signalizaci čekání volání",
        "secretary-settings": "Nastavení sekretářky",
        "active": "Aktivní",
        "secretary": "Sekretářka",
        "exceptions": "Výjimky",
        "callGroups": "Skupiny volání"
    },
    "audio": {
        "audio": "Audio",
        "inputs": "Vstup",
        "outputs": "Výstup",
        "canteditoutputs": "V tomto prohlížeči bohužel nelze upravovat nastavení zvukového výstupu.",
        "ringingoutput": "Výstup vyzvánění",
        "internalRingtone": "Interní vyzváněcí tón",
        "externalRingtone": "Vyzváněcí tón externího hovoru",
        "ringonboth": "Vyzvánět na obou zařízeních",
        "defaultoutput": "Výchozí (probíhající volání se neresetují)",
        "defaultinput": "Výchozí"
    },
    "notifications": {
        "notifications": "Sdělení",
        "pickup": "Zvednout",
        "banner": "Pruh s textem",
        "banner_info": "Při příchodu volání do skupiny zvednutí zobrazit oznamovací pruh s textem",
        "sound_status": "Zvuk",
        "sound_status_info": "Při příchodu volání do skupiny zvednutí přehrát zvukový signál"
    },
    "information": {
        "information": "Informace o klientovi",
        "clientversion": "Klientská verze",
        "showchangelog": "Zobrazit celý protokol změn"
    },
    "callscript": {
        "callscript": "Skript volání",
        "selectcallscript": "Vyberte skript volání",
        "removecallscript": "Odstranit",
        "testcallscript": "Testovat"
    },
    "hotkey": {
        "hotkey": "Klávesové zkratky",
        "movetoforeground": "Přenést do popředí",
        "directcall": "Zavolat přímo",
        "keybidingPermissions": "Povolit oprávnění klávesových zkratek",
        "enableHotkey": "Povolit klávesovou zkratku"
    },
    "contacts": {
        "contacts": "Kontakty",
        "outlook": "Kontakty aplikace Outlook",
        "importOutlookContacts": "Importovat kontakty aplikace Outlook",
        "refreshOutlookContacts": "Aktualizovat kontakty aplikace Outlook",
        "removeOutlookContacts": "Odebrat kontakty aplikace Outlook",
        "status": {
            "success": "Kontakty byly úspěšně importovány.",
            "failure": "Při importu kontaktů došlo k chybě.",
            "running": "Import kontaktů …"
        },
        "currentlyImported": "Počet importovaných kontaktů",
        "outlookExplanation": "Importovat lze kontakty, které jsou udržovány prostřednictvím aplikace Outlook a nacházejí se v systému. Importované kontakty zůstanou po importu v systému a nejsou dostupné v jiných koncových zařízeních."
    },
    "logs": {
        "logs": "Protokoly",
        "send-logs": "Poslat protokoly",
        "delete-logs": "Vymazat protokoly",
        "note": "Přidat poznámku do protokolů",
        "disclaimer": "Protokoly jsou uloženy pouze lokálně a jsou odstraněny při zavření klienta nebo prohlížeče.\nProstřednictvím „odeslat protokoly“ jsou zpřístupněny WTG.",
        "success": "Protokoly byly odeslány.",
        "failure": "Chyba při odesílání protokolů.",
        "running": "Odesílání protokolů …",
        "deleted": "Protokoly byly vymazány.",
        "oldest-log": "Nejstarší protokol"
    },
    "revert": "Zahodit změny",
    "invalidphonenumber": "Neplatné volané číslo.",
    "section_header_second": "KONTAKTY",
    "my_favorites": "Moji oblíbení",
    "all_contacts": "Všechny kontakty",
    "logging_out_message": "Budete odhlášeni",
    "logging_in_message": "Budete přihlášeni",
    "inbound-calls": "Příchozí volání",
    "parked-calls": "Zaparkovaná volání",
    "park_call": "Zaparkovat volání",
    "notes": "Poznámky",
    "add_notes": "Přiložit poznámku",
    "incomingcall": "Příchozí volání",
    "fromconference": "z konference s {0} účastníky",
    "accept": "Přijmout",
    "reject": "Odmítnout",
    "statusType": {
        "available": "Dostupný",
        "busy": "Obsazený",
        "dnd": "Nerušit",
        "away": "Brzy se vrátím",
        "hidden": "Zobrazit jako offline"
    },
    "target": "Cíl",
    "logout": "Odhlásit",
    "changelog": "Protokol změn",
    "currentversion": "Současná verze",
    "previousversion": "Předchozí verze",
    "queues": "Fronty",
    "switchboard": "Rozvaděč",
    "help": "Stránka nápovědy",
    "title": "Můj stav",
    "label-idle": "Pauza",
    "label-active": "Přihlášeno",
    "notLoggedOut": "Všichni přihlášení",
    "wait-time": "Doba čekání",
    "myActiveQueues": "Moje aktivní fronty",
    "noActiveQueues": "Žádné aktivní fronty",
    "active": "Aktivní",
    "onbreak": "V pauze",
    "inactive": "Neaktivní",
    "waitingMemberCount": "Fronta",
    "holding": "Držet",
    "dialing": "Volit",
    "user-cant-be-edited": "Zde nelze upravovat uživatele",
    "contact-wtg": "Obraťte se na WTG.",
    "error": {
        "TOO_MANY_CONTACTS": "Příliš mnoho přiřazených kontaktů",
        "TOO_MANY_EXTENSIONS": "Příliš mnoho přiřazených telefonních poboček",
        "TOO_MANY_VOICEMAILS": "Příliš mnoho přiřazených hlasových zpráv",
        "TOO_MANY_DESTINATIONS": "Příliš mnoho příchozích telefonních čísel",
        "wronglink": "Tento odkaz na obnovení hesla je nefunkční. Zavřete tuto stránku a znovu klikněte na odkaz ve svém e-mailu.",
        "tokeninvalid": "Platnost vašeho obnovení hesla bohužel vypršela. Požádejte o nové prostřednictvím funkce Zapomenuté heslo.",
        "unknown": "Bohužel to nefungovalo, zkuste to znovu.",
        "call-history-loading-error": "Při načítání historie volání došlo k chybě."
    },
    "newuser": "Nový uživatel",
    "errorKey": "Chyba",
    "enabled": "Aktivní",
    "profile-data": "Profilová data",
    "licence": "Licence",
    "firstname": "Jméno",
    "lastname": "Příjmení",
    "organization": "Podnik",
    "username": "Uživatelské jméno",
    "userpassword": "Nové heslo",
    "repeatuserpassword": "Zopakovat heslo",
    "email-address": "E-mailová adresa",
    "extensionnumber": "Číslo telefonní pobočky",
    "will-be-set-on-save": "Nastavuje se náhodně při ukládání",
    "externalnumbers": "Externí telefonní čísla",
    "external-inbound-phone-number": "Příchozí",
    "external-outbound-phone-number": "Odchozí",
    "voicemail-settings": "Hlasová schránka",
    "voicemail-active": "Hlasová schránka je aktivní",
    "voicemail-password": "Heslo hlasové schránky",
    "voicemail-language": "Jazyk hlasové schránky",
    "call-group-settings": "Svozové skupiny",
    "call-group": {
        "active": "Aktivní",
        "groups": "Skupiny",
        "enter-groups": "Zde zadejte skupiny vyzvednutí uživatele.",
        "deleteGroupConfirmationTitle": "Skupiny volání",
        "deleteGroupConfirmationMessage": "Opravdu chcete smazat skupinu \"{name}\"?",
        "not-assigned": "Aktuálně nejste přiřazen do žádné skupiny hovorů",
        "confirm": "Potvrdit",
        "no-confirmation": "Bez potvrzení"
    },
    "call-forward-settings": "Předání volání",
    "phone-number": "Přesměrování volání",
    "at-busy": "Když je obsazeno",
    "no-answer": "Žádná odpověď",
    "not-registered": "Neregistrováno",
    "call-forwarding": {
        "phone-number": "Přesměrování všech hovorů na toto číslo.",
        "at-busy": "Pokud je aktivováno, aktivní poštovní schránka telefonní pobočky bude přepsána.",
        "no-answer": "Pokud je aktivováno, aktivní poštovní schránka bude přepsána.",
        "not-registered": "Přesměrování volání na nové místo určení, pokud je původní místo určení před aktivací schránky nedosažitelné."
    },
    "restriction-errors": {
        "too-short": "Heslo musí obsahovat alespoň {minimum} znaků",
        "regex-errors": {
            "PHONE_NUMBER": "Musí to být telefonní číslo a musí začínat znakem +",
            "EXTENSION_NUMBER": "Může obsahovat pouze číslice a nesmí začínat nulou",
            "EMAIL": "Neplatná e-mailová adresa",
            "DOMAIN_NUMBER_RESTRICTION": "Toto telefonní číslo není pro váš účet povoleno",
            "VOICEMAIL_PASSWORD": "Musí mít 6 až 16 číslic",
            "USERNAME": "Uživatelské jméno se musí skládat z písmen, číslic a znaků „-“, „_“ nebo „.“.",
            "other": "Nesprávný formát"
        },
        "excluded-value-list-errors": {
            "CALL_CENTER_QUEUE": "Toto číslo se již používá pro frontu call centra",
            "RING_GROUP": "Toto číslo je již používáno pro skupinu hovorů",
            "TIME_CONDITION": "Toto číslo se již používá pro časovou podmínku",
            "DESTINATION": "Toto číslo se již používá pro cíl",
            "IVR_MENU": "Toto číslo se již používá pro nabídku IVR",
            "other": "Toto číslo se již používá jinde",
            "EXTENSION": "Toto číslo již používá jiný uživatel"
        },
        "wrong-format": "Nesprávný formát",
        "empty": "Nesmí být prázdné",
        "passwords-not-matching": "Hesla se musí shodovat",
        "duplicate": "Tato hodnota je již přiřazena uživateli {user}",
        "at-least-one-target": "Je třeba přidat alespoň jednu cílovou možnost",
        "unknown-error": "Chyba ověření",
        "unique-username": "Uživatelské jméno {username} je již obsazeno.",
        "unique-extension": "Rozšíření {extension} je již obsazeno.",
        "unique-inbound-number": "Příchozí číslo {inboundNumber} je již obsazeno.",
        "invalid-file-type": "Nesprávný typ souboru",
        "exceeded-quota": "Překročení kvóty pro uživatele",
        "file-too-large": "Soubor je příliš velký. Maximální velikost je {sizeLimit} MB"
    },
    "secretary-function-settings": "Nastavení sekretářky",
    "secretary-function": {
        "active": "Aktivní",
        "target": "Moje sekretářka"
    },
    "ringduration": "{seconds} Zvonilo s",
    "callduration": "{seconds} Telefonováno s",
    "missedcall": "Zmeškané",
    "contactinfo": "Kontaktní údaje",
    "enter-new-message": "Zadejte novou zprávu",
    "unknown-contact": "Neznámý kontakt",
    "invite": "Pozvat partnery",
    "fromconferenceOptions": "z konference bez účastníků | z konference s jedním účastníkem | z konference s {n} účastníky",
    "wtgcloudpure": "WTG Cloud PURE",
    "incomingcallfrom": "Příchozí volání od {0}",
    "incomingcalls": "Žádná příchozí volání | Příchozí volání | {n} Počet příchozích volání",
    "pickup": "Zvednout",
    "hide": "Skrýt",
    "show_all": "Zobrazit vše",
    "search-for-user": "Hledat uživatele",
    "clear-selection": "Vyprázdnit výběr",
    "not-available": "Není k dispozici žádné číslo",
    "min": "min.",
    "sec": "sek.",
    "today": "Dnes",
    "yesterday": "Včera",
    "break-comment": {
        "break-comment": "Důvod přerušení",
        "select-prompt": "Vyberte důvod přerušení",
        "MEETING": "Rozhovor",
        "BACKOFFICE": "Backoffice",
        "BREAK": "Pauza",
        "SUPERVISOR": "Supervizor"
    },
    "answered": "Zodpovězeno",
    "logged-out": "Odhlášeno",
    "available": "Dostupný",
    "take-a-break": "Udělejte si přestávku",
    "choose-break-reason": "Zvolte prosím důvod přestávky.",
    "select-break-reason": "Vyberte důvod přerušení",
    "break": "Pauza",
    "break-reason": "Důvod pauzy",
    "in-call": "V rozhovoru",
    "wrap-up": "Následné zpracování",
    "backdrop_you_are_set_to_break": "Byli jste uvedeni do stavu Pauza.",
    "backdrop_supervisor_set_break": "Váš supervizor změnil váš stav na Pauza. Nejste aktivní a již nedostáváte žádné hovory z fronty. Důvod pauzy je: Pozastaveno nadřízeným.",
    "backdrop_inactive_set_break": "Protože jste přestali přijímat hovory, váš stav byl nastaven na Pauzu. Nejste aktivní a již nedostáváte žádné hovory z fronty. Důvod pauzy je: Z důvodu nečinnosti nastavena Pauza.",
    "backdrop_return_to_active": "Chcete-li být znovu aktivní, musíte změnit svůj stav na Přihlášen.",
    "park": "Parkovat",
    "park-call": "Zaparkovat volání",
    "search-name": "Hledat",
    "edit": "Upravit",
    "done": "Hotovo",
    "all-read": "Vše přečteno",
    "delete": "Smazat",
    "no-one-waiting": "Nikdo nečeká.",
    "everyone-happy": "Všichni jsou šťastní.",
    "call-reasons": {
        "no-reason": "Žádný důvod",
        "customer-data-changed": "Změna údajů o zákaznících",
        "opening-time-inquiry": "Dotaz na otevírací dobu",
        "banking-information": "Bankovní spojení",
        "order": "Objednávka",
        "product-inquiry": "Dotaz na produkt",
        "support": "Podpora"
    },
    "duration": "Doba trvání",
    "groupname": "Název skupiny",
    "to": "Pro",
    "search-for-contact": "Vyhledat kontakt",
    "direct": "Přímo",
    "participants": "Účastníci",
    "contact": "Kontakt",
    "no-number-available": "Není k dispozici žádné číslo",
    "type": "Druh",
    "calltime": "Čas volání",
    "date": "Datum",
    "notice": "Poznámky",
    "waittime": "Doba čekání",
    "Overview": "Přehled",
    "Users": "Uživatel",
    "call-settings": {
        "label": "Nastavení telefonie",
        "RingGroups": "Souhrnné skupiny volání",
        "CallBroadcast": "Vysílání hovoru",
        "Blacklisting": "Černá listina",
        "IVR": "IVR",
        "AnnouncementManagement": "Správa oznámení",
        "OpenTimes": "Svátky/otevírací doba",
        "ParkingFunction": "Funkce parkování",
        "Pickup": "Zvednout",
        "SwitchBoardSettings": "Ústředna"
    },
    "devices-settings": {
        "label": "Terminály",
        "Provisoning": "Zajišťování",
        "Registration": "Registrace"
    },
    "callcenter-settings": {
        "label": "Nastavení call centra",
        "QueuesSettings": "Fronty call centra",
        "Supervisor": "Vedoucí call centra"
    },
    "softclient-settings": {
        "label": "Nastavení softwarového klienta",
        "Shortcuts": "Klávesové zkratky",
        "MultiField": "Více polí"
    },
    "ivr": {
        "ivr": "IVR",
        "IVRs": "IVR Menu",
        "addIVR": "Přidat IVR",
        "editIVR": "Upravit IVR",
        "deleteIVR": "Smazat IVR",
        "cancel": "Zrušit",
        "search": "Vyhledávání IVR",
        "save": "Uložit",
        "active": "Aktivní",
        "name": "Název",
        "addName": "Přidat název",
        "extension": "Rozšíření",
        "addExtension": "Přidat rozšíření",
        "parentMenu": "Nadřazené menu",
        "selectParentMenu": "Vybrat nadřazené menu",
        "language": "Jazyk",
        "selectLanguage": "Vybrat jazyk",
        "description": "Popis",
        "addDescription": "Přidat popis",
        "greeting": "Pozdrav",
        "selectAnnouncement": "Vybrat pozdrav",
        "long": "Dlouhý",
        "longGreetingDescription": "Dlouhý pozdrav je přehrán při vstupu do menu.",
        "short": "Krátký",
        "shortGreetingDescription": "Krátký pozdrav je přehrán při návratu do menu.",
        "options": "Možnosti",
        "option": "Možnost",
        "target": "Cíl",
        "selectTarget": "Vybrat cíl",
        "order": "Pořadí",
        "defineCallOptions": "Definovat volby volajícího pro IVR menu.",
        "timeout": "Časový limit",
        "time": "Čas",
        "enterTime": "Nastavit časový limit",
        "selectAction": "Vybrat akci",
        "enterTimeFieldDescription": "Počet milisekund čekání po přehrání pozdravu nebo potvrzovací makra.",
        "exitAction": "Akce při ukončení",
        "selectExitActionFieldDescription": "Vybrat akci, která se má provést, pokud IVR opustí.",
        "ringback": "Zvuk při odepisování",
        "ringbackFieldDescription": "Definuje, co volající uslyší, když je voláno cílové číslo.",
        "callerPrefix": "Předpona volajícího",
        "callerPrefixFieldDescription": "Nastaví předponu na jméno volajícího.",
        "selectPrefix": "Vybrat předponu",
        "selectSound": "Vybrat zvuk",
        "invalidOptionSound": "Zvuk neplatné volby",
        "invalidOptionSoundFieldDescription": "Přehrává se, když je vybrána neplatná možnost.",
        "soundOnMenuExit": "Zvuk při opuštění menu",
        "soundOnMenuExitFieldDescription": "Přehrává se při opuštění menu.",
        "pin": "PIN",
        "setPin": "Nastavit PIN",
        "pinFieldDescription": "Volitelné číslo PIN k zabezpečení přístupu do menu.",
        "delayTimeBetweenInput": "Časový limit mezi vstupy",
        "setDelayTimeBetweenInput": "Nastavit časový limit mezi vstupy",
        "delayTimeBetweenInputFieldDescription": "Počet milisekund čekání mezi zadáváním čísel.",
        "maxFailures": "Max. Počet neúspěchů",
        "setMaxFailures": "Nastavit max. počet neúspěchů",
        "maxFailuresFieldDescription": "Maximální počet pokusů před opuštěním.",
        "maximumTimeouts": "Max. časové limity",
        "setMaximumTimeouts": "Nastavit max. časové limity",
        "maximumTimeoutsFieldDescription": "Maximální počet časových limitů před opuštěním.",
        "digitLength": "Délka čísel",
        "setDigitLength": "Nastavit délku čísel",
        "digitLengthFieldDescription": "Maximální počet povolených číslic.",
        "recordingAnnouncement": "Oznámení musí být přidána prostřednictvím hlavního správce WTG. Pokud chcete přidat nové zvukové soubory, pošlete je na adresu: pure.tsc{'@'}wtg.com",
        "deleteIvrConfirmationTitle": "Nabídka IVR",
        "deleteIvrConfirmationMessage": "Opravdu chcete smazat IVR \"{name}\"?"
    },
    "pickupGroups": {
        "search": "Vyhledávání skupin pro vyzvednutí",
        "members-number": "Počet členů skupiny",
        "addPickupGroup": "Přidání skupiny pro vyzvednutí",
        "editPickupGroup": "Upravit skupinu vyzvednutí",
        "pickupGroupsHeader": "Skupiny pro vyzvednutí",
        "numberOfMembers": "Počet členů",
        "members": "Členové",
        "notBlank": "Pole nesmí být prázdné!",
        "noMembers": "Seznam členů nesmí být prázdný!",
        "nameTaken": "Název se již používá!",
        "deleteGroupConfirmationTitle": "Vyzvednutí skupiny",
        "deleteGroupConfirmationMessage": "Opravdu chcete smazat skupinu vyzvednutí \"{name}\"?"
    },
    "call-center-queues": {
        "logged-queues": "Přiřazené fronty",
        "search": "Vyhledávání front v call centru",
        "queues": "Fronty",
        "call-center-queues": "Fronty v call centru",
        "cancel": "Zahodit změny",
        "save": "Uložit",
        "addQueue": "Přidat frontu",
        "editQueue": "Upravit frontu",
        "deleteQueue": "Odstranění fronty",
        "name": "Jméno",
        "addName": "Přidat jméno",
        "extension": "Číslo",
        "selectExtension": "Zvolte číslo",
        "greeting": "Ohlášení",
        "selectGreeting": "Zvolte ohlášení",
        "strategy": "Strategie",
        "selectStrategy": "Zvolte strategii",
        "description": "Popis",
        "addDescription": "Přidat popis",
        "thresholds": "Prahová hodnota",
        "value": "Hodnota",
        "redStart": "Červená od",
        "orangeStart": "Oranžová od",
        "numberOfWaiting": "Počet čekajících volajících",
        "loggedLessThan": "Přihlášeno méně než (v %)",
        "inBreakMoreThan": "O přestávce více než (v %)",
        "availableLessThan": "K dispozici méně než (v %)",
        "thresholdsSectionDescription": "Nastavení prahových hodnot pro vizuální upozornění.",
        "agents": "Agenti",
        "tierLevel": "Úroveň úrovně",
        "tierPosition": "Pozice na úrovni",
        "addAgent": "Vybraný agent",
        "level": "Úroveň",
        "pos": "Pozice",
        "agentsSectionDescription": "Úrovně přiřazují agenty do front.",
        "supervisor": "Vedoucí pracovník",
        "addSupervisor": "Zvolte vedoucího pracovníka",
        "supervisorSectionDescription": "Zvolte jednoho nebo více správců pro tuto frontu.",
        "musicOnHold": "Hudba na počkání",
        "selectMusicOnHold": "Výběr podržené hudby",
        "order": "Objednávka",
        "selectOrder": "Zvolte objednávku",
        "recording": "Nahrávka",
        "recordingToggleDescription": "Uložte nahrávku.",
        "timeBaseValue": "Základní časové skóre",
        "timeBaseValueFieldDescription": "Volba, zda se doba, kterou volající strávil v systému, započítává do čekací doby.",
        "timeout": "Čas odložení",
        "selectWaitingTime": "Nastavení maximální čekací doby",
        "maxWaitingTime": "Maximální čekací doba",
        "maxWaitingTimeFieldDescription": "Doba čekání v sekundách před provedením akce.",
        "maxWaitingTimeNoAgent": "Maximální čekací doba bez agenta",
        "maxWaitingTimeNoAgentFieldDescription": "Doba, kterou volající stráví ve frontě před plánovaným provedením vybrané akce.",
        "maxWaitingTimeNoAvailableAgent": "Maximální doba čekání bez dostupného agenta",
        "maxWaitingTimeNoAvailableAgentFieldDescription": "Doba, kterou volající stráví ve frontě před plánovaným provedením vybrané akce.",
        "timeoutAction": "Čas odložení akce",
        "timeoutActionFieldDescription": "Nastavení akce, která se má provést po dosažení maximální čekací doby.",
        "selectAction": "Vybrat Čas odložení akce",
        "tierRules": "Pravidla pro jednotlivé úrovně",
        "tierRulesToggleDescription": "Určete, zda se na frontu mají vztahovat pravidla úrovně.",
        "tierRuleWaitMultiply": "Prodloužení čekací doby pro pravidla pro jednotlivé úrovně",
        "tierRuleWaitMultiplyToggleDescription": "Prodloužení čekací doby, pokud volající čeká do další úrovně.",
        "tierRuleNoAgentNoWait": "Žádný agent, žádná čekací doba",
        "tierRuleNoAgentNoWaitToggleDescription": "Pokud není k dispozici žádný agent, volající přejde přímo na další úroveň.",
        "discardCall": "Opustit volajícího",
        "discardCallFieldDescription": "Zadejte, po kolika sekundách má být volání zahozeno.",
        "selectTime": "Nastavený čas",
        "abandonedResumeAllowed": "Obnovení opuštěného volajícího",
        "abandonedResumeAllowedToggleDescription": "Umožnit volajícímu obnovit jeho pozici ve frontě.",
        "callerPrefix": "Předvolba volajícího",
        "setPrefix": "Nastavení předpony",
        "callerPrefixFieldDescription": "Zadejte předponu, která se má zobrazit před telefonním číslem příchozím volajícím.",
        "announceSound": "Zvukové upozornění",
        "announceSoundFieldDescription": "Čekající zpráva, která se volajícímu přehraje v opakujících se intervalech.",
        "selectSound": "Výběr zvuku",
        "announceFrequency": "Frekvence oznamování",
        "announceFrequencyFieldDescription": "Po kolika sekundách se má čekající zpráva opakovaně přehrát.",
        "ring-all": "Všechny zvoní",
        "longest-idle-agent": "Agent s nejdelším volným časem",
        "round-robin": "Točení v kruzích",
        "top-down": "Shora dolů",
        "agent-with-least-talk-time": "Agent s nejkratší dobou hovoru",
        "agent-with-fewest-calls": "Agent s nejmenším počtem hovorů",
        "sequentially-by-agent-order": "Postupně podle pořadí agentů",
        "sequentially-by-next-agent-order": "Postupně podle pořadí dalšího agenta",
        "random": "Náhodně",
        "deleteQueueConfirmationTitle": "Fronty call centra",
        "deleteQueueConfirmationMessage": "Opravdu chcete smazat frontu \"{name}\"?",
        "addQueueSuccess": "Fronta byla úspěšně přidána.",
        "addQueueError": "Při přidávání fronty nastal problém",
        "editQueueSuccess": "Fronta byla úspěšně upravena.",
        "editQueueError": "Problém nastal při úpravě fronty",
        "removeQueueSuccess": "Fronta byla úspěšně odstraněna.",
        "removeQueueError": "Problém nastal při mazání fronty",
        "callerOutboundName": "Odchozí název",
        "callerOutboundNameDescription": "Zadejte jméno odchozího externího volajícího.",
        "callerOutboundNumber": "Odchozí číslo",
        "callerOutboundNumberDescription": "Zadejte odchozí číslo externího volajícího.",
        "incomingCalls": "Volající (fronta)"
    },
    "ring-groups": {
        "search": "Vyhledávání skupin volání",
        "active": "Aktivní",
        "callGroups": "Skupiny lovu",
        "add": "Přidat",
        "deleteCallGroup": "Odstranění skupiny",
        "name": "Název",
        "addName": "Přidat název",
        "groups": "Členové",
        "extension": "Pobočka",
        "addExtension": "Vybrat pobočku",
        "greeting": "Pozdrav",
        "selectGreeting": "Vybrat pozdrav",
        "strategy": "Strategie",
        "selectStrategy": "Vybrat strategii",
        "description": "Popis",
        "addDescription": "Přidat popis",
        "save": "Uložit",
        "cancel": "Zahodit změny",
        "addCallGroup": "Přidat skupinu",
        "editCallGroup": "Upravit skupinu",
        "delay": "Zpozdit",
        "timeLimit": "Časového limitu",
        "accept": "Přijmout",
        "targetByTimeLimit": "Cíl podle časového limitu",
        "selectTarget": "Vybrat cíl",
        "addTarget": "Přidat cíl",
        "targetsSectionDescription": "Přidání cílů a parametrů do skupiny kroužků.",
        "callTimeout": "Časový limit volání",
        "chooseCallTimeout": "Vybrat časový limit volání",
        "callerIdName": "Jméno volajícího",
        "selectCallerIdName": "ID volajícího Vytočit název",
        "callerIdNameFieldDescription": "Nastavit jméno ID volajícího pro odchozí externí hovory.",
        "callerIdNumber": "Číslo volajícího",
        "selectCallerIdNumber": "Vybrat číslo volajícího",
        "callerIdNumberFieldDescription": "Nastavení čísla ID volajícího pro odchozí externí hovory.",
        "busyTone": "Zaneprázdněný tón",
        "busyToneFieldDescription": "Určete, co volající slyší, když je cíl volán.",
        "selectSound": "Vybrat tón",
        "recall": "Zpětné volání",
        "recallFieldDescription": "Určuje, co volající slyší, když je cíl volán.",
        "groupMembers": "Členové skupiny",
        "selectContact": "Vybrat kontakt",
        "forwarding": "Přesměrování",
        "forwardingFieldDescription": "Vyberte, zda se má v rámci skupiny použít přesměrování hovorů.",
        "followMe": "Následujte mě",
        "followMeFieldDescription": "Vyberte možnost \"Následujte mě\" pro cíl skupiny zvonění.",
        "missedCalls": "Zmeškané hovory",
        "missedCallsFieldDescription": "Vyberte, zda má být někdo upozorněn e-mailem.",
        "emailAddress": "E-mailová adresa",
        "target": "Cíl",
        "targets": "Cíle",
        "typeOfNotification": "Typ oznámení",
        "callGroupForwarding": "Přeposílání skupin lovu",
        "callGroupForwardingFieldDescription": "Přesměrování volané skupiny hovorů na jiné místo určení.",
        "selectNumber": "Vyberte číslo",
        "feesForwarding": "Přesměrování poplatků za volání",
        "selectFeesForwarding": "Vybrat přesměrování poplatků za volání",
        "callGroupForward": "Přesměrování skupiny lovu",
        "callGroupForwardFieldDescription": "Přesměrování celé lovecké skupiny na vybraný cíl.",
        "simultaneous": "Současně",
        "sequence": "Sekvence",
        "enterprise": "Podnik",
        "rollover": "Rollover",
        "random": "Náhodný"
    },
    "no": "Ne",
    "caller": "Volající",
    "call-center": {
        "agent-activity": {
            "wrapup": "Dokončení",
            "in-wrapup": "V Dokončení",
            "logged-in": "Přihlášen",
            "available": "Dostupné Na",
            "break": "Pauza",
            "in-call": "Ve Výzvě",
            "log-in": "Přihlášení",
            "logged-out": "Odhlášení"
        },
        "call-analytics": {
            "in-call": "Ve výzvě",
            "waiting": "Čekající",
            "abandoned": "Zrušený",
            "handled": "Přijatý"
        },
        "agents-in-queue": "Zástupci v této frontě",
        "tier": "Úroveň",
        "no-logged-in-agents": "V této frontě nejsou v současné době přihlášeni žádní agenti.",
        "todays-calls": "Dnešní hovory",
        "queue": "Fronta",
        "direct-call": "Přímé volání"
    },
    "members": "Příchozí hovory (fronta)",
    "waiting": "Čekání",
    "time": "Čas",
    "view-details": "Zobrazit podrobnosti",
    "admin-user-form": {
        "select-user": "Vyberte prosím uživatele",
        "user-doesnt-exist": "Tento uživatel neexistuje",
        "successfully-created": "Uživatel byl úspěšně vytvořen.",
        "successfully-updated": "Uživatel byl úspěšně aktualizován.",
        "validation-error": "Ve vašem formuláři byly chyby. Opravte je prosím a zkuste to znovu."
    },
    "contact-form": {
        "delete-error": "Kontakt nelze smazat",
        "save-success": "Kontakt byl úspěšně uložen",
        "enterExtension": "Zadejte číslo přípojky",
        "enterCountry": "Zadejte název země",
        "enterMobile": "Zadejte číslo mobilního telefonu",
        "enterWork": "Zadejte pracovní telefonní číslo",
        "delete-success": "Kontakt úspěšně odstraněn",
        "put-error": "Kontakt nebylo možné aktualizovat",
        "enterFirstname": "Zadejte křestní jméno",
        "enterLastname": "Zadejte příjmení",
        "enterLocation": "Zadejte název lokality",
        "enterEmail": "Zadejte e-mailovou adresu",
        "save-error": "Kontakt se nepodařilo uložit",
        "enterPosition": "Zadejte pozici",
        "enterAddress": "Zadejte ulici a číslo",
        "enterPostcode": "Zadejte poštovní směrovací číslo",
        "enterCompany": "Zadejte název společnosti",
        "put-success": "Kontakt byl úspěšně aktualizován"
    },
    "medialibrary-settings": {
        "categories": {
            "GREETING": "Uvítání",
            "RINGTONE": "Vyzváněcí tón",
            "MUSIC_ON_HOLD": "Hudba při čekání",
            "IVR": "IVR hlášení",
            "OTHER": "Jiné"
        },
        "columns": {
            "language": "Jazyk",
            "usable-for": "Použitelné pro",
            "uploaded-by": "Uploadnul",
            "type": "Typ",
            "used-in": "Používá se v",
            "category": "Kategorie",
            "name": "Název",
            "upload-time": "Čas nahrání"
        },
        "usage-type": {
            "queue": "Fronta call centra",
            "ring_group": "Skupina Ring Group",
            "ivr": "IVR nabídka"
        },
        "audio-language-placeholder": "Výběr jazyka",
        "AudioLibrarySettings": "Audio",
        "header": "Zvukové soubory",
        "label": "Zvuková knihovna",
        "used-in-title": "Používá se v",
        "delete-ivr-dependencies-msg": "Tento zvuk je v současné době používán, nejprve je třeba odstranit závislosti.",
        "confirm-delete": "Ano, vymazat",
        "cancel": "Zrušit",
        "audio-name": "Název",
        "audio-users-placeholder": "Vyberte uživatele",
        "category": "Kategorie",
        "user": "Uživatel",
        "language": "Jazyk",
        "accessible-for": "Použitelné pro",
        "file": "Soubor",
        "browse-files": "Procházení souborů",
        "edit-audio-title": "Úprava zvukového souboru",
        "audio-usable-for-placeholder": "Zvolte použití",
        "title": "Zvuková knihovna",
        "delete-audio-title": "Odstranění zvukového souboru",
        "audio-name-placeholder": "Přidání názvu zvuku",
        "drag-drop": "Přetáhněte sem soubory",
        "add-audio-title": "Přidání nového zvukového souboru",
        "delete-confirmation-msg": "Opravdu chcete odstranit zvukový soubor \"{name}\"?",
        "delete-greeting-dependencies-msg": "Tento zvuk je právě používán, určitě jej chcete odstranit? Pokud ano, soubor se odstraní a nahradí se výchozím zvukovým souborem.",
        "search": "Vyhledávání zvukových souborů",
        "audio-category-placeholder": "Vyberte kategorii",
        "legal-disclaimer": "Nahráváním, používáním nebo zobrazováním hudby, audiovizuálního obsahu, zvukových nahrávek, fotografií, uměleckých děl a dalších forem médií chráněných autorským právem na platformě Cloud PURE zaručujete společnosti WTG, že máte povolení a/nebo licenci k použití takového obsahu. Pokud tak učiníte bez povolení nebo licence od vlastníka, porušujete tím zákon o autorských právech a může dojít k odstranění takového obsahu.",
        "or-choose": "nebo",
        "edit-success": "Zvukový soubor byl úspěšně aktualizován",
        "save-success": "Zvukový soubor byl úspěšně vytvořen"
    },
    "personalContact": "Osobní kontakt",
    "globalContact": "Centrálně spravovaný kontakt",
    "validators": {
        "nonempty": "Nemůže být prázdný"
    },
    "outlookContact": "Importováno z aplikace Outlook",
    "pureContact": "Kontakt PURE",
    "personalContacts": "Osobní kontakty",
    "globalContacts": "Centrální kontakty",
    "pureContacts": "Kontakty PURE",
    "signWithMicrosoft": "Přihlášení pomocí účtu Microsoft",
    "sso-settings": {
        "enable": "Zapnuto",
        "SSOSettings": "Nastavení jednotného přihlášení",
        "label": "Jednotné přihlášení",
        "title": "Jednotné přihlášení"
    }
}
