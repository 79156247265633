import type { QueryKey, UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { useQuery } from '@tanstack/vue-query';
import { computed, type ComputedRef, type MaybeRefOrGetter, toValue } from 'vue';
import { useSessionStore } from '@/stores/useSessionStore';

type AuthenticatedQueryParams<TQueryFnData, TError, TData> = {
  queryKey: QueryKey; // Ensure to define or import QueryKey as per your setup
  queryFn: () => Promise<TQueryFnData>;
} & UseQueryOptions<TQueryFnData, TError, TData>; // Make queryConfig optional;

//TODO after the changes to the axios interceptor this probably can be deleted. I will do this in separate commit if there will be no errors during testing.
export const useAuthenticatedQuery = <TQueryFnData = unknown, TError = unknown, TData = TQueryFnData>(
  params: AuthenticatedQueryParams<TQueryFnData, TError, TData> & { enabled?: MaybeRefOrGetter<boolean> }
): UseQueryReturnType<TData, TError> => {
  const $session = useSessionStore();

  // Reactive enabled condition combining session state and provided enabled condition
  const isEnabled: ComputedRef<boolean> = computed(() => {
    const isAuthenticated = $session._session.isAuthenticated;
    // If enabled is not defined in queryConfig, assume true
    const isExplicitlyDisabled = toValue(params.enabled) === false;
    return isAuthenticated && !isExplicitlyDisabled;
  });
  // Override the enabled property with the reactive condition
  const modifiedQueryConfig = { ...params, enabled: isEnabled };
  return useQuery<TQueryFnData, TError, TData>(modifiedQueryConfig);
};
