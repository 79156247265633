{
    "contact-details": {
        "email": "E-Mail",
        "title": "Kontaktdaten",
        "location": "Ort",
        "address": "Adresse",
        "postcode": "Postleitzahl",
        "contact-delete-title": "Löschen von Kontakten",
        "edit": "Kontaktinformationen bearbeiten",
        "add": "Neuen Kontakt hinzufügen",
        "country": "Land",
        "position": "Position",
        "contact-delete": "Möchten Sie diesen Kontakt löschen?"
    },
    "integration-settings": {
        "title": "Azure-Integrationseinstellungen",
        "description": "Durch die Eingabe der Daten aktivieren Sie automatisch die Azure-Integration, welche die Synchronisation der Anwesenheit in MS Teams und die Kontaktsynchronisation umfasst.",
        "label": "Integration",
        "AzureSettings": "Azure Integration",
        "azure-client-id": "Anwendungs-ID (Client)",
        "azure-client-secret": "Geheimer Clientschlüssel-Wert",
        "azure-tenant-id": "Verzeichnis-ID (Mandant)",
        "delete-config-msg": "Möchten Sie die Azure-Konfiguration wirklich löschen?",
        "configuration": "Azure-Konfiguration"
    },
    "invalid-uuid": "Der Wert sollte im UUID-Format sein",
    "invalid-data": "Ungültige Daten angegeben. Bitte überprüfen und erneut versuchen",
    "add": "Hinzufügen",
    "yes-delete": "Ja, löschen",
    "add-user": "Benutzer hinzufügen",
    "closewindowdespitecall": "Es gibt noch einen laufenden Anruf, trotzdem schließen?",
    "admin-portal": "Admin Portal",
    "internal": "Intern",
    "external": "Extern",
    "inbound": "Eingehend",
    "search": "Suche",
    "no": "Nein",
    "unsavedchanges": {
        "validmessage": "Ungespeicherte Änderungen",
        "invalidmessage": "Ungültige Änderungen können nicht gespeichert werden",
        "valid-content": "Es gibt noch einige ungespeicherte Änderungen. Möchten Sie diese vor Ihrer Abreise speichern?",
        "invalid-content": "Es gibt einige ungespeicherte ungültige Änderungen. Möchten Sie diese vor Ihrer Abreise rückgängig machen?",
        "content": "Es gibt einige nicht gespeicherte Änderungen. Möchten Sie diese rückgängig machen, bevor Sie die Seite verlassen?",
        "revert": "Änderungen verwerfen",
        "cancel": "Änderungen anpassen",
        "save": "Speichern"
    },
    "changelicense": {
        "title": "Lizenz wechseln",
        "content": "Wir weisen Sie darauf hin, dass der Wechsel des Lizenztypen kaufmännische Auswirkungen mit sich bringen und zu Veränderungen der Kosten führen kann. Sind Sie sicher, dass Sie die Benutzerlizenz zu der \"{license}\" Lizenz wechseln möchten?",
        "cancel": "Abbrechen",
        "save": "Ja, wechseln"
    },
    "loading": "Wird geladen …",
    "agentspace": "Agent Panel",
    "set-agent-status": "Status des Agenten festlegen",
    "choose-agent-status": "Bitte wählen Sie den Status des Agenten.",
    "set-status": "Status setzen",
    "agents": "Agenten",
    "agent": "Agent",
    "members": "Anrufer (Warteschleife)",
    "queue": "Warteschleife",
    "agent-status": {
        "all": "Alle",
        "in-call": "Im Gespräch",
        "logged-out": "Abgemeldet",
        "logged-in": "Eingeloggt",
        "available": "Verfügbar",
        "break": "Pause",
        "wrap-up": "Nachbearbeitung",
        "inactive": "Inaktiv"
    },
    "homeview": {
        "no-missed-calls": "Es gibt keine neuen verpassten Anrufe.",
        "up-to-date": "Sie sind auf dem neuesten Stand."
    },
    "unknown": "Unbekannt",
    "post-processing-time": "Nachbearbeitungszeit",
    "call-reason": "Grund für Anruf",
    "call-history": "Anrufer-Historie",
    "call_history": "Anruf-Historie",
    "queues-waiting": "Wartende",
    "request_supervisor": "Supervisor anfordern",
    "requests_supervisor": "Supervisor-Anfrage",
    "supervisor": "Supervisor",
    "referred-by": "Weitergeleitet von",
    "diverted-by": "Umgeleitet von",
    "name": "Name",
    "status": "Status",
    "all": "Alle anzeigen",
    "number": "Nummer",
    "position": "Position",
    "waiting-time": "Wartezeit",
    "steady": "Dauer",
    "close": "Schließen",
    "calls": "Anrufe",
    "replayed": "Beantwortet",
    "canceled": "Abgebrochen",
    "service-level": "Service Level",
    "call-detail": "Anruf Detail",
    "phone": "Telefon",
    "offline_message": "Du bist abgemeldet",
    "break_message": "Du machst gerade eine Pause",
    "no_agent": "Du hast keinen zugeordneten Agenten",
    "forward_to": "Weiterleiten",
    "connect_to": "Vermitteln",
    "cancel": "Abbrechen",
    "more": "Mehr",
    "merge": "Vermitteln abschließen",
    "warm": "Warm",
    "cold": "Kalt",
    "hang-up": "Auflegen",
    "parking": "Parken",
    "warmtransfer": "Vermitteln",
    "coldtransfer": "Weiterleiten",
    "hold": "Halten",
    "speaker": "Lautsprecher",
    "unhold": "Zurückholen",
    "mute": "Stumm schalten",
    "addparticipant": "Teilnehmer hinzufügen",
    "soundoutputsettings": "Ausgabeeinstellungen",
    "participant": "Teilnehmer",
    "keypad": "Nummernfeld",
    "dialpad": "Wählen",
    "endcall": "Verlassen",
    "insertnameornumber": "Name oder Nummer eingeben",
    "group": "Gruppe",
    "declined": "Abgelehnt",
    "timeout": "Zeitüberschreitung",
    "busy": "Besetzt",
    "add-contact-number": "Kontakt, Nummer hinzufügen",
    "conference-member": "Konferenz-Teilnehmer",
    "back": "Zurück",
    "failed": "Nicht erreichbar",
    "hung-up": "Aufgelegt",
    "sound-error": "Mikrofon-Probleme",
    "save": "Speichern",
    "extension": "Nebenstelle",
    "phonelabel": {
        "mobile": "Mobil",
        "fax": "Fax",
        "work": "Arbeit",
        "other": "Andere"
    },
    "addresslabel": {
        "work": "Arbeit"
    },
    "video": "Video",
    "work": "Arbeit",
    "favorites": "Favoriten",
    "allContacts": "Alle Kontakte",
    "favoriteAdd": "Zu Favoriten hinzufügen",
    "favoriteRemove": "Aus Favoriten entfernen",
    "importedFrom": "Importiert aus",
    "AssistantTelephoneNumber": "Assistent",
    "BusinessTelephoneNumber": "Geschäftlich",
    "Business2TelephoneNumber": "Geschäftlich 2",
    "BusinessFaxNumber": "Fax geschäftlich",
    "CallbackTelephoneNumber": "Rückmeldung",
    "CarTelephoneNumber": "Auto",
    "company": "Firma",
    "HomeTelephoneNumber": "Privat",
    "Home2TelephoneNumber": "Privat 2",
    "HomeFaxNumber": "Fax privat",
    "ISDNNumber": "ISDN",
    "MobileTelephoneNumber": "Mobiltelefon",
    "OtherFaxNumber": "Weiteres Fax",
    "OtherTelephoneNumber": "Weitere",
    "PrimaryTelephoneNumber": "Haupttelefon",
    "RadioTelephoneNumber": "Funkruf",
    "TelexNumber": "Telex",
    "TTYTDDTelephoneNumber": "Texttelefon",
    "home": "Home",
    "statistics": "Statistiken",
    "hourabbrev": "std",
    "minuteabbrev": "min",
    "secondabbrev": "s",
    "totallogintime": "Anmeldezeit in Summe",
    "averagecallduration": "Durchschnittliche Gesprächszeit",
    "acceptedcalls": "Angenommene Gespräche",
    "missedcalls": "Verpasste Anrufe",
    "nomissedcalls": "Sie haben nichts verpasst.",
    "languages": "Sprachen",
    "language": "Sprache",
    "logIn": "Anmelden",
    "password": "Passwort",
    "stayLoggedIn": "Angemeldet bleiben",
    "forgotPassword": "Passwort vergessen?",
    "userLogin": "Benutzer",
    "autologinfailed": "Automatisches Login fehlgeschlagen",
    "unknownversion": "Unbekannte Version",
    "userneedsdomain": "Fehlgeschlagen: Der Benutzername muss eine Domain enthalten.",
    "userhasnoextension": "Fehlgeschlagen: Der Nutzer hat keine Nebenstelle zugeordnet.",
    "wrongcredentials": "Fehlgeschlagen: Benutzer und Passwort stimmen nicht überein.",
    "inactiveUser": "Fehler: Benutzer ist nicht aktiv.",
    "serverunreachable": "Fehlgeschlagen: Der Server {0} ist nicht erreichbar.",
    "checkwtgcloud": "Meinen Sie wtg.cloud statt wtg.com?",
    "unknownerror": "Unbekannter Fehlerzustand: {0}",
    "mobiletitel": "Telefon",
    "history": "Verlauf",
    "numpad": "Wähltastatur",
    "recordings": "Aufnahmen",
    "voicemails": "Sprachnachrichten",
    "historytabs": {
        "all": "Alle",
        "missed": "Verpasst",
        "inbound": "Eingehend",
        "outbound": "Ausgehend"
    },
    "enternameornumber": "Nummer eingeben",
    "internalextensionnumber": "Durchwahl",
    "externalextensionnumber": "Meine Nummer",
    "request-password-reset-header": "Passwort vergessen?",
    "user-at-dot": "Der Benutzername muss ein {'@'} und einen . enthalten",
    "reset-password": "Passwort zurücksetzen",
    "return-to-login": "Zurück zur Anmeldung",
    "passwordreset-success-msg": "Um das Passwort zurückzusetzen, wurde an die hinterlegte Adresse eine E-Mail versendet. Bitte überprüfen Sie Ihr Postfach.",
    "not-working": "Das hat leider nicht funktioniert.",
    "error403": "Möglicherweise haben Sie zu häufig das Zurücksetzen des Passworts auf einmal beantragt.",
    "error400": "Bitte überprüfen Sie ihren Benutzernamen und versuchen Sie es nochmal.",
    "error-domain": "Bitte überprüfen Sie die Domain (den Teil hinter dem '{'@'}').",
    "resetsuccesstitle": "Passwort wurde zurückgesetzt",
    "resetsuccessmessage": "Das Passwort wurde zurückgesetzt.",
    "tologin": "Zur Anmeldung",
    "resetpassword": "Passwort zurücksetzen",
    "newpassword": "Neues Passwort",
    "passwordhastocontain8chars": "Das Passwort muss mindestens 8 Zeichen enthalten.",
    "repeatpassword": "Passwort wiederholen",
    "passwordsdontmatch": "Die Passwörter stimmen nicht überein.",
    "savepassword": "Passwort speichern",
    "settings": "Einstellungen",
    "user": {
        "deletePhotoConfirmationTitle": "Profilbild",
        "deletePhotoConfirmationMessage": "Möchten Sie das Bild wirklich löschen?",
        "userprofile": "Profil",
        "profilepicture": "Profilbild",
        "newprofilepicture": "Neues Profilbild",
        "deleteprofilepicture": "Profilbild löschen",
        "uploadprofilepicture": "Hochladen",
        "changepassword": "Passwort ändern",
        "oldpassword": "Altes Passwort",
        "newpassword": "Neues Passwort",
        "repeatnewpassword": "Passwort wiederholen",
        "extension": "Nebenstelle",
        "extensionpassword": "Nebenstellen-Passwort",
        "language": "Sprache",
        "passwordwaschanged": "Passwort geändert.",
        "useatleast8characters": "Das Passwort muss mindestens 8 Zeichen enthalten.",
        "passwordsdontmatch": "Passwörter stimmen nicht überein",
        "passwordchangeerror": "Das hat leider nicht funktioniert. Bitte überprüfen Sie ihr altes Passwort und versuchen Sie es nochmal.",
        "languages": {
            "de": "Deutsch",
            "en": "Englisch",
            "fr": "Französisch",
            "cs": "Tschechisch"
        },
        "disablePhone": "Telefonie im Softclient deaktivieren"
    },
    "callforward": {
        "callforward": "Rufumleitung",
        "target": "Ziel",
        "all": "Rufumleitung alle",
        "alldescription": "Leiten Sie alle Anrufe an das angegebene Ziel.",
        "busy": "Bei Besetzt",
        "busydescription": "Falls aktiviert, wird die aktivierte Mailbox der Nebenstelle überschrieben.",
        "noanswer": "Keine Antwort",
        "noanswerdescription": "Falls aktiviert, wird die aktivierte Mailbox der Nebenstelle überschrieben.",
        "notregistered": "Nicht registriert",
        "notregistereddescription": "Rufweiterleitung an neues Ziel, wenn ursprüngliches Ziel nicht erreichbar ist, bevor die Mailbox aktiviert wird."
    },
    "followme": {
        "deleteTargetConfirmationTitle": "Anrufweiterschaltung",
        "deleteTargetConfirmationMessage": "Möchten Sie das Ziel wirklich löschen?",
        "followme": "Anrufweiterschaltung",
        "target": "Ziel",
        "delay": "Verzögerung",
        "timeout": "Abwurfzeit",
        "prompt": "Nachfragen",
        "delete": "Löschen",
        "prompton": "Ein",
        "promptoff": "Aus",
        "adddestination": "Hinzufügen",
        "ignorebusy": "Besetzt ignorieren",
        "ignorebusydescription": "Anruf unterbrechen, wenn ein Ziel besetzt ist."
    },
    "extensionsettings": {
        "extensionsettings": "Leitungseinstellungen",
        "busyonbusydecision": "Mehrere eingehende Anrufe",
        "busyonbusy": "Busy-On-Busy",
        "allowcallwaiting": "Anklopfen erlauben",
        "secretary-settings": "Sekretäreinstellungen",
        "active": "Aktiv",
        "secretary": "Sekretär",
        "exceptions": "Ausnahmen",
        "callGroups": "Sammelrufgruppen"
    },
    "audio": {
        "audio": "Audio",
        "inputs": "Eingabe",
        "outputs": "Ausgabe",
        "canteditoutputs": "Audioausgabe-Einstellungen können in diesem Browser leider nicht bearbeitet werden.",
        "ringingoutput": "Ausgabe klingeln",
        "internalRingtone": "Klingelton intern",
        "externalRingtone": "Klingelton extern",
        "ringonboth": "Auf beiden Geräten klingeln",
        "defaultoutput": "Default (Laufende Anrufe werden nicht zurückgesetzt)",
        "defaultinput": "Default"
    },
    "notifications": {
        "notifications": "Mitteilungen",
        "pickup": "Pickup",
        "banner": "Banner",
        "banner_info": "Bei Eingang eines Anrufes in der Pickup Gruppe Benachrichtigungs-Banner anzeigen",
        "sound_status": "Sound",
        "sound_status_info": "Bei Eingang eines Anrufes in der Pickup Gruppe Sound wiedergeben"
    },
    "information": {
        "information": "Client-Informationen",
        "clientversion": "Client-Version",
        "showchangelog": "Gesamtes Change Log anzeigen"
    },
    "callscript": {
        "callscript": "Anrufskript",
        "selectcallscript": "Anrufskript auswählen",
        "removecallscript": "Entfernen",
        "testcallscript": "Testen"
    },
    "hotkey": {
        "hotkey": "Tastenkürzel",
        "movetoforeground": "In den Vordergrund bringen",
        "directcall": "Direkt anrufen",
        "keybidingPermissions": "Berechtigungen für Tastenkombinationen erlauben",
        "enableHotkey": "Hotkey einschalten"
    },
    "contacts": {
        "contacts": "Kontakte",
        "outlook": "Outlook-Kontakte",
        "importOutlookContacts": "Outlook-Kontakte importieren",
        "refreshOutlookContacts": "Outlook-Kontakte aktualisieren",
        "removeOutlookContacts": "Outlook-Kontakte entfernen",
        "status": {
            "success": "Kontakte erfolgreich importiert.",
            "failure": "Fehler beim Importieren der Kontakte.",
            "running": "Kontakte werden importiert …"
        },
        "currentlyImported": "Anzahl importierter Kontakte",
        "outlookExplanation": "Kontakte, die über Outlook gepflegt sind und sich auf dem System befinden, können importiert werden. Die importierten Kontakte verbleiben nach einem Import auf dem System und sind nicht auf anderen Endgeräten verfügbar."
    },
    "logs": {
        "logs": "Logs",
        "send-logs": "Logs senden",
        "delete-logs": "Logs löschen",
        "note": "Notiz zu Logs hinzufügen",
        "disclaimer": "Logs werden nur lokal gespeichert und beim Beenden des Clients oder des Browsers gelöscht.\nMit 'Logs senden' werden sie der WTG zur Verfügung gestellt.",
        "success": "Logs wurden gesendet.",
        "failure": "Fehler beim Senden der Logs.",
        "running": "Logs werden gesendet …",
        "deleted": "Logs wurden gelöscht.",
        "oldest-log": "Ältestes Log"
    },
    "revert": "Änderungen verwerfen",
    "invalidphonenumber": "Ungültige Rufnummer.",
    "section_header_second": "KONTAKTE",
    "my_favorites": "Meine Favoriten",
    "all_contacts": "Alle Kontakte",
    "logging_out_message": "Sie werden abgemeldet",
    "logging_in_message": "Sie werden angemeldet",
    "inbound-calls": "Eingehende Anrufe",
    "parked-calls": "Geparkte Anrufe",
    "park_call": "Anruf Parken",
    "notes": "Notizen",
    "add_notes": "Notiz hinzufügen",
    "incomingcall": "Eingehender Anruf",
    "fromconference": "aus Konferenz mit {0} Teilnehmern",
    "accept": "Annehmen",
    "reject": "Ablehnen",
    "statusType": {
        "available": "Verfügbar",
        "busy": "Beschäftigt",
        "dnd": "Nicht stören",
        "away": "Bin gleich zurück",
        "hidden": "Als offline anzeigen"
    },
    "target": "Ziel",
    "logout": "Abmelden",
    "changelog": "Change Log",
    "currentversion": "Aktuelle Version",
    "previousversion": "Vorherige Version",
    "queues": "Warteschleifen",
    "switchboard": "Vermittlungsplatz",
    "help": "Hilfsseite",
    "title": "Mein Status",
    "label-idle": "Pause",
    "label-active": "Angemeldet",
    "notLoggedOut": "Alle angemeldeten",
    "wait-time": "Wartezeit",
    "myActiveQueues": "Meine aktiven Warteschleifen",
    "noActiveQueues": "Keine aktiven Warteschleifen",
    "active": "Aktiv",
    "onbreak": "In Pause",
    "inactive": "Inaktiv",
    "waitingMemberCount": "Warteschleife",
    "holding": "Halten",
    "dialing": "Wählen",
    "user-cant-be-edited": "Benutzer kann hier nicht bearbeitet werden",
    "contact-wtg": "Bitte wenden Sie sich an die WTG.",
    "error": {
        "TOO_MANY_CONTACTS": "Zu viele zugeordnete Kontakte",
        "TOO_MANY_EXTENSIONS": "Zu viele zugeordnete Nebenstellen",
        "TOO_MANY_VOICEMAILS": "Zu viele zugeordnete Voicemails",
        "TOO_MANY_DESTINATIONS": "Zu viele eingehende Rufnummern",
        "wronglink": "Dieser Passwort-Reset-Link ist fehlerhaft. Bitte schließen Sie diese Seite und klicken Sie nochmal auf den Link in Ihrer E-Mail.",
        "tokeninvalid": "Ihr Passwortreset ist leider abgelaufen. Bitte beantragen Sie ein neues über die Passwort-Vergessen-Funktion.",
        "unknown": "Das hat leider nicht funktioniert, bitte versuchen Sie es nochmal.",
        "call-history-loading-error": "Beim Laden der Anrufliste ist ein Fehler aufgetreten."
    },
    "newuser": "Neuer Benutzer",
    "errorKey": "Fehler",
    "enabled": "Aktiv",
    "profile-data": "Profil-Daten",
    "licence": "Lizenz",
    "firstname": "Vorname",
    "lastname": "Nachname",
    "organization": "Unternehmen",
    "username": "Benutzername",
    "userpassword": "Neues Passwort",
    "repeatuserpassword": "Passwort wiederholen",
    "email-address": "E-Mail Adresse",
    "extensionnumber": "Nebenstellen-Nummer",
    "will-be-set-on-save": "Wird beim Speichern zufällig gesetzt",
    "externalnumbers": "Externe Rufnummern",
    "external-inbound-phone-number": "Eingehend",
    "external-outbound-phone-number": "Ausgehend",
    "voicemail-settings": "Voicemail",
    "voicemail-active": "Voicemail aktiv",
    "voicemail-password": "Voicemail-Passwort",
    "voicemail-language": "Sprache der Voicemail",
    "call-group-settings": "Pickupgruppen",
    "call-group": {
        "active": "Aktiv",
        "groups": "Gruppen",
        "enter-groups": "Geben Sie hier die Pickupgruppen des Nutzers ein.",
        "deleteGroupConfirmationTitle": "Gruppen",
        "deleteGroupConfirmationMessage": "Möchten Sie die Gruppe wirklich löschen \"{name}\"?",
        "not-assigned": "Sie sind derzeit keiner Anrufgruppe zugewiesen",
        "confirm": "Bestätigen",
        "no-confirmation": "Keine Bestätigung"
    },
    "call-forward-settings": "Rufweiterleitung",
    "phone-number": "Rufumleitung",
    "at-busy": "Bei Besetzt",
    "no-answer": "Keine Antwort",
    "not-registered": "Nicht registriert",
    "call-forwarding": {
        "phone-number": "Leiten Sie alle Anrufe an das angegebene Ziel.",
        "at-busy": "Falls aktiviert, wird die aktive Mailbox der Nebenstelle überschrieben.",
        "no-answer": "Falls aktiviert, wird die aktive Mailbox überschrieben.",
        "not-registered": "Rufweiterleitung an neues Ziel, wenn ursprüngliches Ziel nicht erreichbar ist, bevor die Mailbox aktiviert wird."
    },
    "restriction-errors": {
        "too-short": "Muss mindestens {minimum} Zeichen enthalten",
        "regex-errors": {
            "PHONE_NUMBER": "Muss eine Telefonnummer sein und mit + anfangen",
            "EXTENSION_NUMBER": "Darf nur Ziffern enthalten und nicht mit einer 0 anfangen",
            "EMAIL": "Ungültige E-Mail-Adresse",
            "DOMAIN_NUMBER_RESTRICTION": "Diese Telefonnummer ist für Ihren Account nicht erlaubt",
            "VOICEMAIL_PASSWORD": "Muss aus 6 bis 16 Ziffern bestehen",
            "USERNAME": "Nutzername muss aus Buchstaben, Ziffern, -, _ oder . bestehen.",
            "other": "Falsches Format"
        },
        "excluded-value-list-errors": {
            "CALL_CENTER_QUEUE": "Diese Nummer wird schon für eine Callcenter-Warteschleife verwendet",
            "RING_GROUP": "Diese Nummer wird bereits für eine Anrufgruppe verwendet",
            "TIME_CONDITION": "Diese Nummer wird schon für eine Time-Condition verwendet",
            "DESTINATION": "Diese Nummer wird schon für eine Destination verwendet",
            "IVR_MENU": "Diese Nummer wird schon für ein IVR-Menü verwendet",
            "other": "Diese Nummer wird schon woanders verwendet",
            "EXTENSION": "Diese Nummer wird bereits von einem anderen Benutzer verwendet"
        },
        "wrong-format": "Falsches Format",
        "empty": "Darf nicht leer sein",
        "passwords-not-matching": "Die Passwörter müssen übereinstimmen",
        "duplicate": "Dieser Wert ist schon für den Nutzer {user} vergeben",
        "at-least-one-target": "Mindestens eine Zieloption muss hinzugefügt werden",
        "unknown-error": "Validierungsfehler",
        "unique-username": "Der Benutzername {username} wird bereits verwendet.",
        "unique-extension": "Die Erweiterung {extension} wird bereits verwendet.",
        "unique-inbound-number": "Die eingehende Nummer {inboundNumber} ist bereits vergeben.",
        "invalid-file-type": "Ungültiger Dateityp",
        "exceeded-quota": "Überschrittene Quote für Benutzer",
        "file-too-large": "Datei ist zu groß. Maximale Größe beträgt {sizeLimit} MB"
    },
    "secretary-function-settings": "Sekretäreinstellungen",
    "secretary-function": {
        "active": "Aktiv",
        "target": "Mein Sekretär"
    },
    "ringduration": "{seconds} s geklingelt",
    "callduration": "{seconds} s telefoniert",
    "missedcall": "Verpasst",
    "contactinfo": "Kontaktdaten",
    "enter-new-message": "Eine neue Nachricht eingeben",
    "unknown-contact": "Unbekannter Kontakt",
    "invite": "Gesprächspartner einladen",
    "fromconferenceOptions": "aus Konferenz ohne Teilnehmer | aus Konferenz mit einem Teilnehmer | aus Konferenz mit {n} Teilnehmern",
    "wtgcloudpure": "WTG Cloud PURE",
    "incomingcallfrom": "Eingehender Anruf von {0}",
    "incomingcalls": "Keine eingehenden Anrufe | Eingehender Anruf | {n} eingehende Anrufe",
    "pickup": "Pickup",
    "hide": "Ausblenden",
    "show_all": "Alle anzeigen",
    "search-for-user": "Suche nach einem Nutzer",
    "clear-selection": "Auswahl leeren",
    "not-available": "Keine Nummer vorhanden",
    "min": "Min.",
    "sec": "Sek.",
    "today": "Heute",
    "yesterday": "Gestern",
    "break-comment": {
        "break-comment": "Pausengrund",
        "select-prompt": "Pausengrund auswählen",
        "MEETING": "Besprechung",
        "BACKOFFICE": "Backoffice",
        "BREAK": "Pause",
        "SUPERVISOR": "Supervisor"
    },
    "answered": "Beantwortet",
    "logged-out": "Abgemeldet",
    "available": "Verfügbar",
    "take-a-break": "Eine Pause einlegen",
    "choose-break-reason": "Bitte wählen Sie den Grund für Ihre Pause.",
    "select-break-reason": "Pausengrund auswählen",
    "break": "Pause",
    "break-reason": "Pausen-Grund",
    "in-call": "Im Gespräch",
    "wrap-up": "Nachbearbeitung",
    "backdrop_you_are_set_to_break": "Du wurdest auf Pause gesetzt.",
    "backdrop_supervisor_set_break": "Dein Supervisor hat deinen Status auf Pause geändert. Du bist nicht aktiv und erhältst keine Anrufe mehr aus der Warteschleife. Der Pausegrund ist: Vom Supervisor auf Pause gesetzt.",
    "backdrop_inactive_set_break": "Weil du keine Anrufe mehr angenommen hast, wurde dein Status auf Pause gesetzt. Du bist nicht aktiv und erhältst keine Anrufe mehr aus der Warteschleife. Der Pausegrund ist: Wegen Inaktivität auf Pause gesetzt.",
    "backdrop_return_to_active": "Um wieder aktiv zu werden, musst du deinen Status auf Angemeldet ändern.",
    "park": "Parken",
    "park-call": "Anruf parken",
    "search-name": "Suche",
    "edit": "Bearbeiten",
    "done": "Fertig",
    "all-read": "Alle gelesen",
    "delete": "Löschen",
    "no-one-waiting": "Keiner wartet.",
    "everyone-happy": "Alle sind glücklich.",
    "call-reasons": {
        "no-reason": "Kein Grund",
        "customer-data-changed": "Änderung Kundendaten",
        "opening-time-inquiry": "Anfrage Öffnungszeiten",
        "banking-information": "Bankverbindung",
        "order": "Bestellung",
        "product-inquiry": "Produktanfrage",
        "support": "Support"
    },
    "duration": "Dauer",
    "groupname": "Gruppenname",
    "to": "An",
    "search-for-contact": "Nach Kontakt suchen",
    "direct": "Direkt",
    "participants": "Teilnehmer",
    "contact": "Kontakt",
    "no-number-available": "Keine Nummer verfügbar",
    "type": "Art",
    "calltime": "Anrufzeit",
    "date": "Datum",
    "notice": "Notizen",
    "waittime": "Wartezeit",
    "Overview": "Übersicht",
    "Users": "Benutzer",
    "call-settings": {
        "label": "Telefonie Einstellungen",
        "RingGroups": "Sammelrufgruppen",
        "CallBroadcast": "Rufen Sie Broadcast an",
        "Blacklisting": "Schwarze Liste",
        "IVR": "IVR",
        "AnnouncementManagement": "Ansagen-Management",
        "OpenTimes": "Feiertage / Öffnungszeiten",
        "ParkingFunction": "Parken Funktion",
        "Pickup": "Pickup",
        "SwitchBoardSettings": "Vermittlungsplatz"
    },
    "devices-settings": {
        "label": "Endgeräte",
        "Provisoning": "Provisionierung",
        "Registration": "Registrierung"
    },
    "callcenter-settings": {
        "label": "Call Center Einstellungen",
        "QueuesSettings": "Call Center Warteschleifen",
        "Supervisor": "Call Center Supervisor"
    },
    "softclient-settings": {
        "label": "Soft-Client Einstellungen",
        "Shortcuts": "Tastaturkürzel",
        "MultiField": "Multi-Feld"
    },
    "ivr": {
        "ivr": "IVR",
        "IVRs": "Sprachmenüs",
        "addIVR": "IVR hinzufügen",
        "editIVR": "IVR Bearbeiten",
        "deleteIVR": "IVR löschen",
        "cancel": "Abbrechen",
        "search": "Suche nach IVR",
        "save": "Speichern",
        "active": "Aktiv",
        "name": "Name",
        "addName": "Name hinzufügen",
        "extension": "Nebenstelle",
        "addExtension": "Nebenstelle hinzufügen",
        "parentMenu": "Übergeordnetes Menü",
        "selectParentMenu": "Übergeordnetes Menü auswählen",
        "language": "Sprache",
        "selectLanguage": "Sprache auswählen",
        "description": "Beschreibung",
        "addDescription": "Beschreibung hinzufügen",
        "greeting": "Begrüßung",
        "selectAnnouncement": "Ansage auswählen",
        "long": "Lang",
        "longGreetingDescription": "Die lange Begrüßung wird beim Aufrufen des Menüs abgespielt.",
        "short": "Kurz",
        "shortGreetingDescription": "Die kurze Begrüßung wird bei der Rückkehr zum Menü abgespielt.",
        "options": "Optionen",
        "option": "Option",
        "target": "Ziel",
        "selectTarget": "Ziel auswählen",
        "order": "Reihenfolge",
        "defineCallOptions": "Definieren Sie Anruferoptionen für das IVR-Menü.",
        "timeout": "Zeitüberschreitung",
        "time": "Zeit",
        "enterTime": "Zeit eingeben",
        "selectAction": "Aktion auswählen",
        "enterTimeFieldDescription": "Zeit in Millisekunden, die nach dem Abspielen der Ansage oder des Bestätigungsmakros gewartet werden soll.",
        "exitAction": "Beendigungsaktion",
        "selectExitActionFieldDescription": "Wählen Sie die Beendigungsaktion aus, die durchgeführt werden soll, wenn die IVR beendet wird.",
        "ringback": "Freizeichenton",
        "ringbackFieldDescription": "Legen Sie fest, was der Anrufer hört, während das Ziel angerufen wird.",
        "callerPrefix": "Anrufer Präfix",
        "callerPrefixFieldDescription": "Legen Sie ein Präfix fest, welches bei eingehenden Anrufen vor der Rufnummer angezeigt werden soll.",
        "selectPrefix": "Präfix wählen",
        "selectSound": "Ton wählen",
        "invalidOptionSound": "Ton bei ungültiger Option",
        "invalidOptionSoundFieldDescription": "Wird abgespielt, wernn eine ungültige Option gewählt wird.",
        "soundOnMenuExit": "Ton beim Verlassen des Menüs",
        "soundOnMenuExitFieldDescription": "Wird beim Verlassen des Menüs abgespielt.",
        "pin": "PIN",
        "setPin": "Set PIN",
        "pinFieldDescription": "Optionale PIN um den Zugang zum Menü zu sichern.",
        "delayTimeBetweenInput": "Verzögerungszeit zwischen Eingabe",
        "setDelayTimeBetweenInput": "Verzögerungszeit zwischen Eingabe",
        "delayTimeBetweenInputFieldDescription": "Zeit in Millisekunden, die zwischen den Ziffern gewartet wird.",
        "maxFailures": "Maximale Fehlangaben",
        "setMaxFailures": "Maximale Fehlangaben",
        "maxFailuresFieldDescription": "Maximale Anzahl an falscher Eingaben, die ein Kunde machen kann, bevor der Anruf beendet wird.",
        "maximumTimeouts": "Maximale Zeitüberschreitungen",
        "setMaximumTimeouts": "Maximale Zeitüberschreitungen festlegen",
        "maximumTimeoutsFieldDescription": "Maximale Anzahl von Wiederholungsversuchen vor dem Beenden.",
        "digitLength": "Ziffernlänge",
        "setDigitLength": "Ziffernlänge einstellen",
        "digitLengthFieldDescription": "Maximal zulässige Anzahl von Ziffern.",
        "recordingAnnouncement": "Die Ansagen müssen über den Master-Admin der WTG eingespielt werden. Wenn Sie neue Audiodateien hinzufügen möchten, schicken Sie diese bitte an: pure.tsc{'@'}wtg.com",
        "deleteIvrConfirmationTitle": "IVR Menü",
        "deleteIvrConfirmationMessage": "Möchten Sie den IVR \"{Name}\" wirklich löschen?"
    },
    "pickupGroups": {
        "search": "Suche nach Pickup Gruppen",
        "members-number": "Anzahl der Gruppenmitglieder",
        "addPickupGroup": "Pickup Gruppe hinzufügen",
        "editPickupGroup": "Pickup Gruppe bearbeiten",
        "pickupGroupsHeader": "Pickup Gruppen",
        "numberOfMembers": "Zahl der Mitglieder",
        "members": "Mitglieder",
        "notBlank": "Die Eingabe darf nicht leer sein!",
        "noMembers": "Die Mitgliederliste darf nicht leer sein!",
        "nameTaken": "Dieser Name ist schon vergeben!",
        "deleteGroupConfirmationTitle": "Pickup Gruppe",
        "deleteGroupConfirmationMessage": "Möchten Sie die Pickup-Gruppe wirklich löschen \"{name}\"?"
    },
    "call-center-queues": {
        "logged-queues": "Zugeordnete Warteschleifen",
        "search": "Suche nach Call Center Warteschleifen",
        "queues": "Warteschleifen",
        "call-center-queues": "Call Center Warteschleifen",
        "cancel": "Abbrechen",
        "save": "Speichern",
        "addQueue": "Warteschleife hinzufügen",
        "editQueue": "Warteschleife bearbeiten",
        "deleteQueue": "Warteschleife löschen",
        "name": "Name",
        "addName": "Name hinzufügen",
        "extension": "Nebenstelle",
        "selectExtension": "Nebenstelle wählen",
        "greeting": "Begrüßung",
        "selectGreeting": "Begrüßung wählen",
        "strategy": "Strategie",
        "selectStrategy": "Strategie wählen",
        "description": "Beschreibung",
        "addDescription": "Beschreibung hinzufügen",
        "thresholds": "Schwellenwerte",
        "value": "Wert",
        "redStart": "Rot ab",
        "orangeStart": "Orange ab",
        "numberOfWaiting": "Anzahl der wartenden Anrufer",
        "loggedLessThan": "Angemeldet weniger als (in %)",
        "inBreakMoreThan": "In Pause mehr als (in %)",
        "availableLessThan": "Verfügbar weniger (als in %)",
        "thresholdsSectionDescription": "Legen Sie Schwellwerte für einen optischen Hinweis fest.",
        "agents": "Agenten",
        "tierLevel": "Level",
        "tierPosition": "Level Pos.",
        "addAgent": "Agent auswählen",
        "level": "Level",
        "pos": "Pos.",
        "agentsSectionDescription": "Level ordnen Agenten eine Prioritätsreihenfolge innerhalb von Warteschleifen zu.",
        "supervisor": "Supervisor",
        "addSupervisor": "Supervisor auswählen",
        "supervisorSectionDescription": "Legen Sie einen oder mehrere Supervisoren für diese Warteschleife fest.",
        "musicOnHold": "Wartemusik",
        "selectMusicOnHold": "Wartemusik wählen",
        "order": "Reihenfolge",
        "selectOrder": "Reihenfolge wählen",
        "recording": "Aufnahme",
        "recordingToggleDescription": "Die Aufnahme speichern.",
        "timeBaseValue": "Zeitbasiswert",
        "timeBaseValueFieldDescription": "Auswahl, ob die Zeit, die der Anrufer im System verbracht hat, als Wartezeit gezählt wird.",
        "timeout": "Zeitüberschreitung",
        "selectWaitingTime": "Wartezeit wählen",
        "maxWaitingTime": "Maximale Wartezeit",
        "maxWaitingTimeFieldDescription": "Die Wartezeit in Sekunden, bevor die ausgewählte Aktion ausgeführt werden soll.",
        "maxWaitingTimeNoAgent": "Max. Wartezeit ohne angemeldeten Agenten",
        "maxWaitingTimeNoAgentFieldDescription": "Die Zeit, die ein Anrufer in der Warteschleife verbringt, bevor die ausgewählte Aktion ausgeführt werden soll.",
        "maxWaitingTimeNoAvailableAgent": "Max. Wartezeit ohne verfügbaren Agenten",
        "maxWaitingTimeNoAvailableAgentFieldDescription": "Die Zeit, die ein Anrufer in der Warteschleife verbringt, bevor die ausgewählte Aktion ausgeführt werden soll.",
        "timeoutAction": "Aktion bei Zeitüberschreitung",
        "timeoutActionFieldDescription": "Legen Sie die Aktion fest, die ausgeführt werden soll, wenn einer der drei oberen Werte greift.",
        "selectAction": "Aktion wählen",
        "tierRules": "Level-Regeln",
        "tierRulesToggleDescription": "Legen Sie fest, ob die Level-Regeln für die Warteschleife gelten sollen.",
        "tierRuleWaitMultiply": "Verlängerung der Level-Regel Wartezeit",
        "tierRuleWaitMultiplyToggleDescription": "Verlängerung der Wartezeit, wenn der Anrufer bis zum nächsten Level wartet.",
        "tierRuleNoAgentNoWait": "Kein Agent, keine Wartezeit",
        "tierRuleNoAgentNoWaitToggleDescription": "Wenn kein Agent verfügbar, geht der Anrufer direkt in das nächste Level.",
        "discardCall": "Anruf verwerfen",
        "discardCallFieldDescription": "Legen Sie fest nach wie vielen Sekunden der Anruf verworfen werden soll.",
        "selectTime": "Zeit wählen",
        "abandonedResumeAllowed": "Verworfene wieder einreihen",
        "abandonedResumeAllowedToggleDescription": "Anrufer erlauben seine Position in der Warteschleife wieder einzunehmen.",
        "callerPrefix": "Anruf Präfix",
        "setPrefix": "Präfix wählen",
        "callerPrefixFieldDescription": "Legen Sie ein Präfix fest, welches bei eingehenden Anrufern vor der Rufnummer angezeigt werden soll.",
        "announceSound": "Warteansage",
        "announceSoundFieldDescription": "Die Warteansage, die dem Anrufer in wiederkehrenden Intervallen vorgespielt werden soll.",
        "selectSound": "Ton wählen",
        "announceFrequency": "Frequenz Warteansage",
        "announceFrequencyFieldDescription": "Nach wie vielen Sekunden die Warteansage wiederkehrend abgespielt werden soll.",
        "ring-all": "Alle klingeln",
        "longest-idle-agent": "Längste Leerlaufzeit",
        "round-robin": "Rundlauf",
        "top-down": "Von oben nach unten",
        "agent-with-least-talk-time": "Agent mit niedrigster Gesprächszeit",
        "agent-with-fewest-calls": "Agent mit dem wenigsten Anrufen",
        "sequentially-by-agent-order": "Sequentiell nach Agenten",
        "sequentially-by-next-agent-order": "Sequentiell nach nächstem Agenten",
        "random": "Zufällig",
        "deleteQueueConfirmationTitle": "Call Center Warteschleifen",
        "deleteQueueConfirmationMessage": "Möchten Sie die Warteschleife \"{name}\" wirklich löschen?",
        "editQueueSuccess": "Die Warteschleife wurde erfolgreich bearbeitet.",
        "editQueueError": "Problem beim Bearbeiten der Warteschleife aufgetreten",
        "removeQueueSuccess": "Die Warteschleife wurde erfolgreich gelöscht.",
        "removeQueueError": "Problem beim Löschen der Warteschleife aufgetreten",
        "addQueueSuccess": "Die Warteschleife wurde erfolgreich hinzugefügt.",
        "addQueueError": "Problem beim Hinzufügen der Warteschleife aufgetreten",
        "callerOutboundName": "Ausgehender Name",
        "callerOutboundNameDescription": "Geben Sie den ausgehenden Namen des externen Anrufers ein.",
        "callerOutboundNumberDescription": "Geben Sie die ausgehende Nummer des externen Anrufers ein.",
        "callerOutboundNumber": "Ausgehende Nummer",
        "incomingCalls": "Anrufer (Warteschleife)"
    },
    "ring-groups": {
        "search": "Suche nach Sammelrufgruppen",
        "active": "Aktiv",
        "callGroups": "Sammelrufgruppen",
        "add": "Hinzufügen",
        "deleteCallGroup": "Gruppe löschen",
        "name": "Name",
        "addName": "Name auswählen",
        "groups": "Gruppen",
        "extension": "Nebenstelle",
        "addExtension": "Nebenstelle wählen",
        "greeting": "Begrüßung",
        "selectGreeting": "Begrüßung wählen",
        "strategy": "Strategie",
        "selectStrategy": "Strategie wählen",
        "description": "Beschreibung",
        "addDescription": "Beschreibung hinzufügen",
        "save": "Speichern",
        "cancel": "Abbrechen",
        "addCallGroup": "Gruppe hinzufügen",
        "editCallGroup": "Gruppe Bearbeiten",
        "delay": "Verzögern",
        "timeLimit": "Zeitlimit",
        "accept": "Annehmen",
        "targetByTimeLimit": "Ziel nach Zeitlimit",
        "selectTarget": "Ziel wählen",
        "addTarget": "Ziel hinzufügen",
        "targetsSectionDescription": "Fügen Sie der Ringgruppe Ziele und Parameter hinzu.",
        "callTimeout": "Zeitüberschreitung eines Anrufs",
        "chooseCallTimeout": "Zeitüberschreitung wählen",
        "callerIdName": "Anrufer-ID Name",
        "selectCallerIdName": "Anrufer-ID Name wählen",
        "callerIdNameFieldDescription": "Legen Sie den Namen der Anrufer-ID für ausgehende externe Anrufe fest.",
        "callerIdNumber": "Anrufer-ID-Nummer",
        "selectCallerIdNumber": "Anrufer-ID-Nummer wählen",
        "callerIdNumberFieldDescription": "Legen Sie die Anrufer-ID-Nummer für ausgehende externe Anrufe fest.",
        "busyTone": "Freizeichenton",
        "busyToneFieldDescription": "Legen Sie fest, was der Anrufer hört, während das Ziel angerufen wird.",
        "selectSound": "Ton wählen",
        "recall": "Rückruf",
        "recallFieldDescription": "Legt fest, was der Anrufer hört, während das Ziel angerufen wird.",
        "groupMembers": "Gruppen Mitglieder",
        "selectContact": "Kontakt auswählen",
        "forwarding": "Rufumleitung",
        "forwardingFieldDescription": "Wählen Sie aus, ob die Rufumleitung innerhalb der Gruppe angewendet werden soll.",
        "followMe": "Anrufweiterschaltung",
        "followMeFieldDescription": "Wählen Sie aus, ob die Anrufweiterschaltung innerhalb der Gruppe angewendet werden soll.",
        "missedCalls": "Verpasste Anrufe",
        "missedCallsFieldDescription": "Wahlen Sie aus ob jemand per E-Mail benachrichtigt werden soll.",
        "emailAddress": "E-Mail Adresse",
        "target": "Ziel",
        "targets": "Ziele",
        "typeOfNotification": "Art der Benachrichtigung",
        "callGroupForwarding": "Sammelrufgruppen Weiterleitung",
        "callGroupForwardingFieldDescription": "Weiterleitung einer angerufenen Rufgruppe an ein anderes Ziel.",
        "selectNumber": "Nummer wählen",
        "feesForwarding": "Weiterleitung von Gebühren",
        "selectFeesForwarding": "Weiterleitung von Gebühren wählen",
        "callGroupForward": "Sammelrufgruppe umleiten",
        "callGroupForwardFieldDescription": "Umleitung der gesamten Sammelrufgruppe an ein ausgewähltes Ziel.",
        "simultaneous": "Gleichzeitige",
        "sequence": "Sequenz",
        "enterprise": "Unternehmen",
        "rollover": "Rollover",
        "random": "Zufällig"
    },
    "call-center": {
        "call-analytics": {
            "waiting": "Wartend",
            "in-call": "Im Gespräch",
            "abandoned": "Abgebrochen",
            "handled": "Angenommen"
        },
        "agent-activity": {
            "wrapup": "Nachbereitung",
            "in-wrapup": "Nachbereitung",
            "logged-out": "Abgemeldet",
            "log-in": "Einloggen",
            "logged-in": "Eingeloggt",
            "available": "Verfügbar",
            "break": "Pause",
            "in-call": "Im Gespräch"
        },
        "agents-in-queue": "Agenten in dieser Warteschleife",
        "tier": "Level",
        "no-logged-in-agents": "Derzeit sind keine Agenten in dieser Warteschleife angemeldet.",
        "todays-calls": "Heutige Anrufe",
        "queue": "Warteschlange",
        "direct-call": "Direkter Anruf"
    },
    "caller": "Anrufer",
    "view-details": "Details anzeigen",
    "waiting": "Warten",
    "time": "Zeit",
    "admin-user-form": {
        "select-user": "Bitte wählen Sie einen Benutzer aus",
        "user-doesnt-exist": "Dieser Benutzer existiert nicht",
        "successfully-created": "Benutzer wurde erfolgreich erstellt.",
        "successfully-updated": "Benutzer wurde erfolgreich aktualisiert.",
        "validation-error": "Es sind Fehler aufgetreten. Bitte beheben Sie diese und versuchen Sie es erneut."
    },
    "contact-form": {
        "enterLastname": "Nachname eingeben",
        "enterCountry": "Ländernamen eingeben",
        "enterPostcode": "Postleitzahl eingeben",
        "save-error": "Kontakt konnte nicht gespeichert werden",
        "put-success": "Kontakt erfolgreich aktualisiert",
        "put-error": "Kontakt konnte nicht aktualisiert werden",
        "delete-success": "Kontakt erfolgreich gelöscht",
        "enterCompany": "Firmenname eingeben",
        "enterEmail": "E-Mail Adresse eingeben",
        "delete-error": "Kontakt konnte nicht gelöscht werden",
        "enterFirstname": "Vornamen eingeben",
        "enterPosition": "Position eingeben",
        "enterMobile": "Handynummer eingeben",
        "enterAddress": "Straße und Hausnummer eingeben",
        "enterExtension": "Durchwahlnummer eingeben",
        "enterWork": "Telefonnummer der Arbeitsstelle eingeben",
        "enterLocation": "Ortsname eingeben",
        "save-success": "Kontakt erfolgreich gespeichert"
    },
    "medialibrary-settings": {
        "title": "Audio-Bibliothek",
        "AudioLibrarySettings": "Audio",
        "label": "Audio-Bibliothek",
        "search": "Suche nach Audiodateien",
        "header": "Audio-Dateien",
        "confirm-delete": "Ja, löschen",
        "used-in-title": "Verwendet in",
        "columns": {
            "category": "Kategorie",
            "used-in": "Verwendet in",
            "name": "Name",
            "type": "Typ",
            "language": "Sprache",
            "usable-for": "Verwendbar für",
            "upload-time": "Upload-Zeit",
            "uploaded-by": "Hochgeladen von"
        },
        "categories": {
            "IVR": "IVR-Ansage",
            "MUSIC_ON_HOLD": "Warteschleifenmusik",
            "GREETING": "Grußwort",
            "RINGTONE": "Klingelton",
            "OTHER": "Andere"
        },
        "delete-audio-title": "Audiodatei löschen",
        "delete-greeting-dependencies-msg": "Diese Audiodatei wird derzeit verwendet. Sind Sie sicher, dass Sie sie löschen möchten? Wenn ja, wird die Datei gelöscht und durch die Standard-Audiodatei ersetzt.",
        "delete-confirmation-msg": "Sind Sie sicher, dass Sie die Audiodatei \"{name}\" löschen möchten?",
        "cancel": "Abbrechen",
        "add-audio-title": "Neue Audiodatei hinzufügen",
        "audio-name": "Name",
        "audio-name-placeholder": "Audioname hinzufügen",
        "audio-category-placeholder": "Kategorie auswählen",
        "audio-language-placeholder": "Sprache auswählen",
        "audio-users-placeholder": "Benutzer auswählen",
        "category": "Kategorie",
        "user": "Benutzer",
        "language": "Sprache",
        "drag-drop": "Dateien hierher ziehen und ablegen",
        "or-choose": "oder",
        "browse-files": "Dateien durchsuchen",
        "edit-audio-title": "Audiodatei bearbeiten",
        "audio-usable-for-placeholder": "Verwendung auswählen",
        "usage-type": {
            "ivr": "IVR-Menü",
            "queue": "Call-Center-Warteschlange",
            "ring_group": "Ringgruppe"
        },
        "delete-ivr-dependencies-msg": "Dieses Audio wird derzeit verwendet, Abhängigkeiten müssen zuerst entfernt werden.",
        "legal-disclaimer": "Indem Sie urheberrechtlich geschützte Musik, audiovisuelle Inhalte, Tonaufnahmen, Fotografien, Kunstwerke und andere Formen von Medien auf WTG Cloud PURE hochladen, verwenden oder anzeigen, garantieren Sie der WTG, dass Sie die Erlaubnis und/oder eine Lizenz zur Verwendung dieser Inhalte haben. Wenn Sie dies ohne Erlaubnis oder Lizenz des Eigentümers tun, verstoßen Sie gegen das Urheberrechtsgesetz und können zur Entfernung dieser Inhalte führen.",
        "file": "Datei",
        "accessible-for": "Verwendbar für",
        "save-success": "Audiodatei erfolgreich erstellt",
        "edit-success": "Audiodatei erfolgreich aktualisiert"
    },
    "personalContacts": "Persönliche Kontakte",
    "personalContact": "Persönlicher Kontakt",
    "globalContacts": "Zentrale Kontakte",
    "pureContacts": "PURE-Kontakte",
    "globalContact": "Zentral verwalteter Kontakt",
    "signWithMicrosoft": "Anmelden mit Microsoft-Konto",
    "pureContact": "PURE-Kontakt",
    "outlookContact": "Aus Outlook importiert",
    "validators": {
        "nonempty": "Kann nicht leer sein"
    },
    "sso-settings": {
        "enable": "Aktiviert",
        "SSOSettings": "Single Sign-On Einstellungen",
        "label": "Single Sign-On",
        "title": "Single Sign-On"
    }
}
