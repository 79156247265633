import type { AxiosRequestConfig } from 'axios';
import { useSessionStore } from '@/stores/useSessionStore';
import { getAxiosInstance } from '@/api/axios';

type HttpRequestConfig = Omit<AxiosRequestConfig<any>, 'data'>;

export const httpGet = async <R>(endpoint: string, config?: HttpRequestConfig) => {
  return getAxiosInstance()
    .get<R>(endpoint, config)
    .then((r) => r.data);
};

export const httpPost = async <D extends object | null, R>(endpoint: string, data?: D, config?: HttpRequestConfig): Promise<R> => {
  const isFormData = data instanceof FormData;
  const headers = {
    ...config?.headers,
    ...(isFormData ? { 'Content-Type': 'multipart/form-data' } : null),
  };
  const finalConfig = { ...config, headers };
  return getAxiosInstance().post(endpoint, data, finalConfig);
};

export const httpPut = async <D extends object | null, R>(endpoint: string, data?: D, config?: HttpRequestConfig): Promise<R> => {
  const isFormData = data instanceof FormData;
  const headers = {
    ...config?.headers,
    ...(isFormData ? { 'Content-Type': 'multipart/form-data' } : null),
  };
  const finalConfig = { ...config, headers };
  return getAxiosInstance().put(endpoint, data, finalConfig);
};

export const httpPatch = async <D extends object | null, R>(endpoint: string, data?: D, config?: HttpRequestConfig): Promise<R> => {
  const isFormData = data instanceof FormData;
  const headers = {
    ...config?.headers,
    ...(isFormData ? { 'Content-Type': 'multipart/form-data' } : null),
  };
  const finalConfig = { ...config, headers };
  return getAxiosInstance().patch(endpoint, data, finalConfig);
};

export const httpDelete = async <R>(endpoint: string, config?: HttpRequestConfig): Promise<R> => {
  return getAxiosInstance().delete(endpoint, config);
};

export const buildAuthorizationHeader = () => {
  const $session = useSessionStore();
  return { Authorization: 'Bearer ' + $session._session.token };
};
