import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import { SessionLength } from '@/constants/browserStorage';
import { useInitSessionData } from '@/composables/useInitSessionData';
import { createDomainAxiosInstance } from '@/api/axios';
import { getStorage } from '@/utils/storage';
import { useSSOCookie } from '@/composables/useSSOSession';

export interface AuthenticatedSession {
  token: string;
  expiryDate: string;
  domain: string;
  userUuid: string;
  isAuthenticated: true;
  sessionLength: SessionLength;
}

export interface UnauthenticatedSession {
  token?: string;
  expiryDate?: string;
  domain?: string;
  userUuid?: string;
  isAuthenticated: false;
  sessionLength?: SessionLength;
}

export type UnknownSession = Omit<Session, 'isAuthenticated'>;
export type Session = AuthenticatedSession | UnauthenticatedSession;

const isTokenNotExpired = (dateInSeconds: string | number | null | undefined): boolean => {
  if (dateInSeconds == null) return false;
  const nowInSeconds = Math.floor(Date.now() / 1_000);
  return +dateInSeconds >= nowInSeconds;
};

export const isAuthenticatedSession = (session: UnknownSession): session is AuthenticatedSession =>
  !!(isTokenNotExpired(session.expiryDate) && session.token && session.userUuid && session.domain);

export const EmptySession: UnauthenticatedSession = {
  token: undefined,
  expiryDate: undefined,
  domain: undefined,
  userUuid: undefined,
  isAuthenticated: false,
  sessionLength: undefined,
};

export const useSessionStore = defineStore('sessionStore', () => {
  const __session = ref<Session>(useInitSessionData());
  const queryClient = useQueryClient();

  if (__session.value.isAuthenticated) {
    createDomainAxiosInstance(__session.value.domain);
  }

  const initializeSession = () => {
    const session = useInitSessionData();
    if (session.isAuthenticated) {
      createSession(session);
      createDomainAxiosInstance(session.domain);
    }
  };

  const createSession = (session: AuthenticatedSession) => {
    __session.value = {
      token: session.token,
      expiryDate: session.expiryDate,
      domain: session.domain,
      userUuid: session.userUuid,
      sessionLength: session.sessionLength ?? SessionLength.SHORT,
      isAuthenticated: true,
    };
  };

  const clearSessionData = () => {
    useSSOCookie().clearSessionData();

    getStorage().removeItem('auth_token');
    getStorage().removeItem('domain_name');
    getStorage().removeItem('expires_at');
    getStorage().removeItem('user_uuid');
  };

  const logoutSession = async () => {
    try {
      await queryClient.cancelQueries();
      __session.value = {
        isAuthenticated: false,
        sessionLength: __session.value.sessionLength,
      };
      clearSessionData();
    } catch (e) {
      console.error(e, 'Error occurred during closing session.');
    }
  };

  return { _session: __session, initializeSession, createSession, logoutSession };
});
