import { BrowserStorageKey, SessionLength } from '@/constants/browserStorage';
import { isElectron } from '@/utils/clientUtils';

export const saveToBrowserStorage = (key: string, value: any, storage = localStorage) => {
  if (!key) return;
  else {
    const _value = typeof value !== 'string' ? JSON.stringify(value) : value;
    storage.setItem(key, _value);
  }
};

const getDataFromStorage = (key: string, storage: Storage) => {
  const data = storage.getItem(key);
  if (!data) return null;
  if (data === 'undefined' || data === 'null') return null;
  return data;
};

export const parseDataFromBrowserStorage = <T>(key: string, storage = localStorage): T | null => {
  const data = getDataFromStorage(key, storage);
  if (data == null) return null;
  try {
    return JSON.parse(data);
  } catch (e) {
    console.error(`Parsing ${key} caused an error`, e);
    return null;
  }
};

export const getDataFromBrowserStorage = (key: string, storage?: Storage) => {
  const _storage = storage ?? getStorage();
  return getDataFromStorage(key, _storage);
};

export const getStorage = () => {
  if (isElectron()) return localStorage;
  const sessionLength =
    getDataFromStorage(BrowserStorageKey.SESSION_LENGTH, sessionStorage) || getDataFromStorage(BrowserStorageKey.SESSION_LENGTH, localStorage);
  return sessionLength === SessionLength.LONG ? localStorage : sessionStorage;
};
