import { isAxiosError } from 'axios';

export const getAxiosError = (error: unknown): string | undefined => {
  if (isAxiosError(error)) {
    return error?.response?.data?.message;
  }
  return undefined;
};

export const builtDomainUrl = (domain: string) => {
  if (import.meta.env.DEV) return import.meta.env.VITE_LOCAL_BASE_URL ?? `https://${domain}/wtgapi`;
  return `https://${domain}/wtgapi`;
};

export const buildFormData = (data: any): FormData => {
  const bodyFormData = new FormData();
  for (const key in data) {
    const value = data[key];
    if (value != null) bodyFormData.append(key, value);
    else bodyFormData.append(key, '' as any);
  }
  return bodyFormData;
};
