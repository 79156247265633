import './polyfills/polyfills';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { vuetify } from '@/plugins/vuetify';
import { i18n } from '@/plugins/i18n';
import LogsPlugin from '@/plugins/logs';
import './styles.css';
import { createPinia } from 'pinia';
import { useQueryClient, VueQueryPlugin } from '@tanstack/vue-query';
import { RouteName } from '@/router/routes';
import { useSessionStore } from '@/stores/useSessionStore';
import { fetchLoggedInUser, LOGGED_IN_USER_QUERY_KEY } from '@/api/useLoggedInUser';
import { isAuthorized } from '@/utils/authorizationUtils';

const pinia = createPinia();
const app = createApp(App);

app.use(router);
app.use(pinia);
app.use(VueQueryPlugin);
app.use(vuetify);
app.use(i18n);
app.use(LogsPlugin);

router.isReady().then(async () => {
  app.mount('#app');
});

router.beforeResolve(async (to, from, next) => {
  const $session = useSessionStore();
  const isAuthenticated = $session._session.isAuthenticated;

  if (to.path === '/' || to.path === '') {
    return next({ name: RouteName.Home });
  }

  // Handle authentication required routes
  if (to.meta.requiresAuth && !isAuthenticated) {
    return next({
      name: RouteName.Login,
      query: to.path !== '/' ? { redirect: to.path } : undefined,
    });
  }
  //Handle users with active existing credentials going accessing Login Page
  if (to.name === RouteName.Login && isAuthenticated) {
    return next({ name: RouteName.Home });
  }

  //Handle routes that requires permissions
  if (to.meta.requiredPermissions && isAuthenticated) {
    const loggedInUser = await useQueryClient().ensureQueryData({
      queryKey: [LOGGED_IN_USER_QUERY_KEY],
      queryFn: () => fetchLoggedInUser($session._session.userUuid),
    });

    if (!isAuthorized(loggedInUser, to.meta.requiredPermissions)) {
      return next({ name: RouteName.Home });
    }
  }

  return next();
});
