import { useSessionStore } from '@/stores/useSessionStore';
import axios, { type AxiosInstance } from 'axios';
import { builtDomainUrl } from '@/utils/httpUtils';
import { useAuthService } from '@/api/useAuthService';
import { RouteName } from '@/router/routes';
import router from '@/router';

// it will be created after user logs in as we don't know domain in advance
let axiosInstance: AxiosInstance;

export const createDomainAxiosInstance = (domain: string) => {
  axiosInstance = axios.create({
    baseURL: builtDomainUrl(domain),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  axiosInstance.interceptors.request.use(requestInterceptor);
  axiosInstance.interceptors.response.use((r) => r, responseErrorInterceptor);

  let isRefreshingToken = false;
  let queuedRequestWithExpiredToken: (() => void)[] = [];

  const processQueuedRequests = () => {
    queuedRequestWithExpiredToken.forEach((callback) => callback());
    queuedRequestWithExpiredToken = [];
  };

  const queueRequestWithExpiredToken = (originalRequest: any) => {
    return new Promise((resolve) => {
      queuedRequestWithExpiredToken.push(() => {
        resolve(axiosInstance(originalRequest));
      });
    });
  };

  async function requestInterceptor(request: any) {
    const originalRequest = request;
    const $session = useSessionStore();
    const { token, expiryDate } = $session._session;

    if (!token || !expiryDate) return request;
    if (isRefreshingToken) return queueRequestWithExpiredToken(originalRequest);

    if (isTokenNearlyExpired(expiryDate)) {
      try {
        isRefreshingToken = true;
        await refreshAccessToken();
        processQueuedRequests();
      } catch (error) {
        isRefreshingToken = false;
        console.error('An error happened while refreshing the token.', error);
        await handleAuthError();
        return Promise.reject(error);
      } finally {
        isRefreshingToken = false;
      }
    }

    const authorizationHeaders = { Authorization: 'Bearer ' + token };
    request.headers = { ...request.headers, ...authorizationHeaders };
    return request;
  }

  async function responseErrorInterceptor(error: any) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshingToken) return queueRequestWithExpiredToken(originalRequest);

      originalRequest._retry = true;
      isRefreshingToken = true;

      try {
        await refreshAccessToken();
        processQueuedRequests();
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        console.error('An error happened while refreshing the token.', refreshError);
        await handleAuthError();
        return Promise.reject(refreshError);
      } finally {
        isRefreshingToken = false;
      }
    }
    console.error('Unexpected error occurred.', error);
    return Promise.reject(error);
  }
};

const refreshAccessToken = async (): Promise<any> => {
  try {
    await useAuthService().refreshToken();
  } catch (error) {
    console.error('Error during token refresh.', error);
    throw new Error(`Error during token refresh + ${error}`);
  }
};

const handleAuthError = async () => {
  const $session = useSessionStore();
  await $session.logoutSession();

  const currentRoute = router.currentRoute.value;
  const redirectPath = currentRoute.path !== '/public/login' ? currentRoute.fullPath : undefined;

  await router.push({
    name: RouteName.Login,
    query: redirectPath ? { redirect: redirectPath } : undefined,
  });
};

export const isTokenNearlyExpired = (expirationToken: string | number | undefined) => {
  const now = Math.floor(Date.now() / 1000); // Current time in seconds
  const thresholdSeconds = 60; // 1 minute before expiration
  const expiryTimestamp = +(expirationToken || 0);
  return expiryTimestamp - now < thresholdSeconds;
};

export const getAxiosInstance = () => axiosInstance;
