import { EmptySession, type Session } from '../stores/useSessionStore';
import { useSSOSession } from '@/composables/useSSOSession';
import { useStorageSession } from '@/composables/useStorageSession';

export const useInitSessionData = (): Session => {
  const ssoSession = useSSOSession();
  const storageSession = useStorageSession();

  if (storageSession.isAuthenticated) return storageSession;
  if (ssoSession.isAuthenticated) return ssoSession;
  return EmptySession;
};
