import { useCookies } from '@vueuse/integrations/useCookies';
import { EmptySession, isAuthenticatedSession, type Session, type UnknownSession } from '@/stores/useSessionStore';
import { useRoute } from 'vue-router';
import { SessionLength } from '@/constants/browserStorage';
import { isLocalhost } from '@/utils/browserUtils';

interface SSOParams {
  session: UnknownSession;
}

export const useSSOCookie = (): SSOParams & { clearSessionData: () => void } => {
  const cookies = useCookies(['user_uuid', 'auth_token', 'domain_name', 'expires_at']);

  const { user_uuid, expires_at, auth_token, domain_name } = cookies.getAll<{
    user_uuid: string;
    auth_token: string;
    domain_name: string;
    expires_at: string;
  }>();

  const clearSessionData = () => {
    cookies.remove('user_uuid');
    cookies.remove('auth_token');
    cookies.remove('domain_name');
    cookies.remove('expires_at');
  };

  return {
    session: {
      userUuid: user_uuid,
      token: auth_token,
      domain: domain_name,
      expiryDate: expires_at,
      sessionLength: SessionLength.LONG,
    },
    clearSessionData,
  };
};

export const useSSOParams = (): SSOParams => {
  const $route = useRoute();
  const { token, user_uuid, domain, expire } = $route.query;

  return {
    session: {
      userUuid: user_uuid as string,
      token: token as string,
      domain: domain as string,
      expiryDate: expire as string,
      sessionLength: SessionLength.LONG,
    },
  };
};

export const useSSOSession = (): Session => {
  // todo bring it back when we will add cookie implementation
  // const useSSOData = isLocalhost() ? useSSOParams : useSSOCookie;
  const { session } = useSSOParams();
  return isAuthenticatedSession(session) ? { ...session, isAuthenticated: true } : EmptySession;
};
