<template>
  <router-view />
</template>

<script setup lang="ts">
import { RouterView } from 'vue-router';
import { useInitAppStore } from '@/stores/useInitAppStore';

useInitAppStore();
</script>
