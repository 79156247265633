import { defineStore } from 'pinia';
import { useSessionStore } from '@/stores/useSessionStore';
import { onMounted, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { BrowserStorageKey, SessionLength } from '@/constants/browserStorage';
import { isElectron } from '@/utils/clientUtils';
import { saveToBrowserStorage } from '@/utils/storage';

export const useInitAppStore = defineStore('initStore', () => {
  const { locale } = useI18n();
  const $sessionStore = useSessionStore();

  // This should trigger only for credentials from url as they are not available when useSessionStore is triggered.
  onMounted(() => {
    if (!$sessionStore._session.isAuthenticated) {
      $sessionStore.initializeSession();
    }
  });

  watchEffect(() => {
    const session = $sessionStore._session;

    // Language should always be kept in local storage
    saveToBrowserStorage(BrowserStorageKey.LANGUAGE, locale.value);

    // for electron credentials need to be stored in localstorage to be able to download toast avatar that is opened in new window
    const requiresLocalStorage = session.sessionLength === SessionLength.LONG || isElectron();
    const browserStorage = requiresLocalStorage ? localStorage : sessionStorage;

    saveToBrowserStorage(BrowserStorageKey.LOGIN_TOKEN, session.token, browserStorage);
    saveToBrowserStorage(BrowserStorageKey.LOGIN_TOKEN_EXPIRY, session.expiryDate, browserStorage);
    saveToBrowserStorage(BrowserStorageKey.LOGGED_IN_USER_UUID, session.userUuid, browserStorage);
    saveToBrowserStorage(BrowserStorageKey.DOMAIN, session.domain, browserStorage);
    saveToBrowserStorage(BrowserStorageKey.SESSION_LENGTH, session.token ? session.sessionLength : null, browserStorage);
  });
});
