export function isElectron() {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf(' electron/') > -1;
}

export function isWindows() {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf('(windows') > -1;
}

export function isMac() {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf('mac os x') != -1;
}

export function getPlatformString() {
  return `${isElectron() ? 'electron' : 'web'}, ${isWindows() ? 'windows' : 'unix'}`;
}

export function getRevision(): string {
  return `v${APP_REVISION}-${GIT_HASH}`;
}

export const getClientInfo = (contactHeader?: string) => {
  return JSON.stringify({
    version: getRevision(),
    is_windows: isWindows(),
    is_electron: isElectron(),
    contact_header: contactHeader || 'none',
  });
};
